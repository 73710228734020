import {
  Button,
  DatePicker,
  Divider,
  Input,
  Popconfirm,
  Skeleton,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import { ErrorMessage } from "../ErrorMessage.tsx";
import TextArea from "antd/es/input/TextArea";
import { ProductListModalSelectable } from "../ProductListModalSelectable.tsx";
import { OrderService } from "../../services/OrderService.ts";
import { useLocation } from "wouter";
import dayjs from "dayjs";
import TrackingNumbersForm from "../TrackingNumbersForm.jsx";
import { WarehouseService } from "../../services/WarehouseService.ts";
import { WarehouseSelect } from "../WarehouseSelect.tsx";
import { RightLayout } from "../RightLayout.tsx";
import { ProductRow } from "./ProductRow.tsx";
import { LinkOutlined } from "@ant-design/icons";

interface EntryOrderFormProps {
  mode: "create" | "edit";
  orderId?: string;
}

export const EntryOrderForm = ({ mode, orderId }: EntryOrderFormProps) => {
  const [location, setLocation] = useLocation();
  const [homeMadeDirty, setHomeMadeDirty] = useState(false);
  const [showProductListSelector, setShowProductListSelector] = useState(false);
  const [products, setProducts] = useState<any>([]);
  const [orderData, setOrderData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [warehouses, setWarehouses] = useState<any>([]);
  const [warehouseToRemoveStock, setWarehouseToRemoveStock] =
    useState<any>("N/A");
  const [daysPassedSinceOrderDate, setDaysPassedSinceOrderDate] =
    useState<any>("");

  const form = useForm({
    defaultValues: {
      title: "",
      comments: "",
      orderDate: "",
      orderSentDate: "",
      orderReceivedDate: "",
      products: [],
      trackingNumbers: [{ number: "" }],
      warehouseToRemoveStock: "N/A",
    },
    mode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "trackingNumbers",
  });

  useEffect(() => {
    refreshOrder();
    refreshWarehouses();
  }, [orderId]);

  const refreshWarehouses = async () => {
    WarehouseService.getAllWarehouses().then((data) => {
      setWarehouses(data.data);
    });
  };

  const refreshOrder = async () => {
    if (orderId) {
      setIsLoading(true);
      new OrderService().getOrder(orderId).then((data) => {
        setOrderData(data.data);
        setProducts(data.data.products);
        form.reset({
          title: data.data.title,
          comments: data.data.comments,
          orderDate: data.data.orderDate ? dayjs(data.data.orderDate) : "",
          orderSentDate: data.data.orderSentDate
            ? dayjs(data.data.orderSentDate)
            : "",
          orderReceivedDate: data.data.orderReceivedDate
            ? dayjs(data.data.orderReceivedDate)
            : "",
          products: data.data.products,
          trackingNumbers: data.data.trackingNumbers,
          warehouseToRemoveStock: data.data.warehouseToRemoveStock || "N/A",
          alibabaLink: data.data.alibabaLink || "",
        });
        setWarehouseToRemoveStock(data.data.warehouseToRemoveStock || "N/A");
        const dateObject = dayjs(data.data.orderSentDate);
        const today = dayjs();
        const differenceInDays = today.diff(dateObject, "day");
        setDaysPassedSinceOrderDate(
          isNaN(differenceInDays) ? "" : differenceInDays
        );

        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      });
    }
  };

  const handleAddProduct = (data) => {
    setShowProductListSelector(false);
    setProducts([
      ...products,
      ...data.map((product) => ({ asin: product, price: 0, quantity: 0 })),
    ]);
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      setHomeMadeDirty(true);
      form.setValue("products", (products) => {
        return [...products, { asin: element, price: 0, quantity: 0 }];
      });
    }
  };

  const handleDeleteProduct = (index) => {
    const newProducts = products.filter((product, i) => i !== index);
    setProducts(newProducts);
    form.setValue("products", newProducts);
  };

  const handleQuantityChange = (index, value) => {
    const newProducts = products.map((product, i) => {
      if (i === index) {
        return { ...product, quantity: value };
      }
      return product;
    });

    setProducts(newProducts);
    form.setValue("products", newProducts);
  };

  const handlePriceChange = (index, value) => {
    const newProducts = products.map((product, i) => {
      if (i === index) {
        return { ...product, price: value };
      }
      return product;
    });
    setProducts(newProducts);
    form.setValue("products", newProducts);
  };

  const handleShippingMethodChange = (index, value) => {
    const newProducts = products.map((product, i) => {
      if (i === index) {
        return { ...product, shippingMethod: value };
      }
      return product;
    });
    setProducts(newProducts);
    form.setValue("products", newProducts);
  };

  const handleCreateEntryOrder = async () => {
    if (isFormValid()) {
      if (mode === "create") {
        const data = form.getValues();

        if (data.warehouseToRemoveStock !== "N/A") {
          data.products.forEach((product) => {
            product.shippingMethod = "Traspaso";
          });
        }

        await new OrderService().createOrder(form.getValues());
        notification.success({
          message: "Albaran creado",
          description: "El albaran ha sido creado correctamente",
        });
        setLocation("/entry-orders");
      } else {
        await new OrderService().updateOrder(orderData._id, form.getValues());
        notification.success({
          message: "Albaran actualizado",
          description: "El albaran ha sido actualizado correctamente",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      notification.error({
        message: "Error al crear albaran",
        description: "Los datos no son válidos",
      });
    }
  };

  const isFormValid = () => {
    form.clearErrors();
    const values = form.getValues();
    let isInvalid = products.some((product) => {
      if (parseInt(product.quantity) === 0) {
        console.error("product.quantity is 0");
        return true;
      }

      if (warehouseToRemoveStock === "N/A") {
        if (parseFloat(product.price) === 0) {
          console.error(`warehouseToRemoveStock is N/A and product.price is 0`);
          return true;
        }
        if (
          product.shippingMethod === "" ||
          product.shippingMethod === null ||
          product.shippingMethod === undefined
        ) {
          console.error(
            "warehouseToRemoveStock is N/A and product.shippingMethod is empty"
          );
          return true;
        }
      }

      return false;
    });

    if (values.orderDate === "") {
      console.error("orderDate is empty");
      form.setError("orderDate", {
        type: "custom",
        message: "La fecha de creación es requerida",
      });

      isInvalid = true;
    }

    if (values.title === "") {
      console.error("title is empty");
      form.setError("title", {
        type: "custom",
        message: "El titulo es requerido",
      });
      isInvalid = true;
    }

    if (values.title.length < 3) {
      console.error("title length is less than 3");
      form.setError("title", {
        type: "custom",
        message: "El titulo debe tener al menos 3 caracteres",
      });
      isInvalid = true;
    }
    if (values.products.length === 0) {
      console.error("products length is 0");
      form.setError("products", {
        type: "custom",
        message: "Debe seleccionar al menos un producto",
      });
      isInvalid = true;
    }

    return !isInvalid;
  };

  const handleReceivedQuantity = async (product, quantity, warehouseId) => {
    const orderService = new OrderService();
    await orderService.updateOrderConfirm(
      orderData._id,
      product._id,
      quantity,
      warehouseId
    );
    refreshOrder();
  };

  const handleFinishEntryOrder = async () => {
    const orderService = new OrderService();
    await orderService.completeOrder(orderData._id);
    setLocation("/entry-orders");
  };

  if (isLoading) {
    return (
      <div>
        <Skeleton />
      </div>
    );
  }

  return (
    <RightLayout>
      {(form.formState.isDirty || homeMadeDirty) && (
        <div
          style={{
            height: "100px",
            backgroundColor: "#AF3800",
            position: "fixed",
            zIndex: 100,
            width: "100vw",
            left: 0,
            top: 0,
            display: "flex",

            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <h1 style={{ color: "white" }}>
              Ojo, tienes que salvar antes de confirmar el stock de los
              productos
            </h1>
            <h2 style={{ color: "white" }}>
              si no lo haces, puedes romper el stock de los productos
            </h2>
          </div>
        </div>
      )}

      {showProductListSelector && (
        <ProductListModalSelectable
          showOnlyBaseProducts={false}
          showOnlyComposedProducts={false}
          handleOk={handleAddProduct}
          handleCancel={() => {
            setShowProductListSelector(false);
          }}
        />
      )}
      <div>
        {mode === "edit" ? (
          <div
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h2>Editar pedido de entrada {orderId}</h2>
            <span> {daysPassedSinceOrderDate} días pasados desde envío</span>
          </div>
        ) : (
          <h2>Nuevo pedido de entrada</h2>
        )}
        <div className="dg-item">
          <div>Titulo</div>
          <Controller
            name="title"
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => {
              const handleAutoName = () => {
                form.setValue(
                  "title",
                  "Albaran_" + dayjs().format("YYYY-MM-DD_HH:mm:ss")
                );
              };
              return (
                <>
                  <div className="d-flex">
                    <Input {...field} />
                    <Button type="primary" onClick={handleAutoName}>
                      Auto Nombre
                    </Button>
                  </div>
                  <ErrorMessage form={form} name="title" />
                </>
              );
            }}
          />
        </div>
        <div>
          <div>Alibaba link</div>

          <Controller
            name="alibabaLink"
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <>
                  <div className="d-flex">
                    {field.value && (
                      <LinkOutlined
                        style={{ color: "#007bff", marginRight: 10 }}
                        onClick={() => {
                          window.open(`${field.value}`, "_blank");
                        }}
                      />
                    )}
                    <Input {...field} />
                  </div>
                  <ErrorMessage form={form} name="title" />
                </>
              );
            }}
          />
        </div>
        <Divider />
        <div>
          <div>Eliminar stock de almacen</div>
          <p style={{ fontSize: 12, fontStyle: "italic", color: "#AF3800" }}>
            Puedes seleccionar N/A (no aplica) y no se eliminara el stock de un
            almacen concreto. Si seleccionas alguna opcion, en el almacen que
            selecciones se elminara la cantidad de stock de cada producto
          </p>
          <Controller
            name="warehouseToRemoveStock"
            control={form.control}
            render={({ field }) => {
              const handleChange = (value: any) => {
                setWarehouseToRemoveStock(value);
                field.onChange(value);
              };
              return (
                <>
                  <WarehouseSelect
                    value={field.value}
                    onChange={handleChange}
                    style={{ width: 200 }}
                  />
                  <ErrorMessage form={form} name="warehouseToRemoveStock" />
                </>
              );
            }}
          />
        </div>
        <Divider />
        <div>
          <div>Comentarios</div>
          <Controller
            name="comments"
            control={form.control}
            render={({ field }) => (
              <>
                <TextArea {...field} />
                <ErrorMessage form={form} name="comments" />
              </>
            )}
          />
        </div>
        <Divider />
        <div>
          <TrackingNumbersForm
            fields={fields}
            append={append}
            remove={remove}
            control={form.control}
          />
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            justifyItems: "center",
          }}
        >
          <div>
            Fecha creación
            <Controller
              name="orderDate"
              control={form.control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <>
                    <DatePicker {...field} />
                    <ErrorMessage form={form} name="orderDate" />
                  </>
                );
              }}
            />
          </div>
          <div>
            FechaEnvio
            <Controller
              name="orderSentDate"
              control={form.control}
              render={({ field }) => (
                <>
                  <DatePicker {...field} />
                  <ErrorMessage form={form} name="orderSentDate" />
                </>
              )}
            />
          </div>
          <div>
            Fecha recibido
            <Controller
              name="orderReceivedDate"
              control={form.control}
              render={({ field }) => (
                <>
                  <DatePicker {...field} />
                  <ErrorMessage form={form} name="orderReceivedDate" />
                </>
              )}
            />
          </div>
        </div>
        <Divider />

        <div>
          <div className="dg-item">Productos</div>
          <p style={{ fontSize: 12, fontStyle: "italic", color: "#AF3800" }}>
            Antes de usar esta sección, tienes que guardar el albaran si has
            hecho algun cambio!!!
          </p>
          {products.map((product, index) => {
            return (
              <ProductRow
                isDirty={form.formState.isDirty || homeMadeDirty}
                key={index}
                product={product}
                index={index}
                handleDeleteProduct={handleDeleteProduct}
                handleQuantityChange={handleQuantityChange}
                handlePriceChange={handlePriceChange}
                handleShippingMethodChange={handleShippingMethodChange}
                mode={mode}
                handleReceivedQuantity={handleReceivedQuantity}
                warehouses={warehouses}
                warehouseToRemoveStock={warehouseToRemoveStock}
              />
            );
          })}
        </div>
        <div style={{ marginTop: 12 }}>
          <Button
            type="dashed"
            onClick={() => setShowProductListSelector(true)}
          >
            Añadir producto
          </Button>
        </div>
        <Divider />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="primary" onClick={handleCreateEntryOrder}>
            {mode === "create" ? "Crear albaran" : "Actualizar albaran"}
          </Button>
          {mode !== "create" && (
            <Popconfirm
              title="Completar albaran"
              description="Completar albaran"
              onConfirm={handleFinishEntryOrder}
              okText="Si"
              cancelText="No"
            >
              <Button danger>Completar albaran</Button>
            </Popconfirm>
          )}
        </div>
        <div style={{ paddingBottom: 24 }}> </div>
      </div>
    </RightLayout>
  );
};

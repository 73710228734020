import React from "react";
import { useForm } from "react-hook-form";
import "./LoginPage.scss";
import { LoginService } from "../services/LoginService.ts";
import { notification } from "antd";

export const LoginPage = ({ onLogin }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    LoginService.login(data.username, data.password).then((res) => {
      notification.success({
        message: "Login Successful",
        description: "You are now logged in",
      });
      onLogin({ token: res.data.token });
    });
  };

  return (
    <div className="login-pre-container">
      <div className="login-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex d-flex-m">
            <div style={{ width: 120, marginTop: 6 }}>
              <label htmlFor="username">Usuario</label>
            </div>

            <input
              style={{ width: "100%" }}
              id="username"
              type="username"
              {...register("username", { required: "username is required" })}
            />
            {errors.username && <div>{errors.username.message}</div>}
          </div>
          <div className="d-flex">
            <div style={{ width: 120, marginTop: 6 }}>
              <label htmlFor="password">Password</label>
            </div>
            <input
              style={{ width: "100%" }}
              id="password"
              type="password"
              {...register("password", { required: "Password is required" })}
            />
            {errors.password && <div>{errors.password.message}</div>}
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

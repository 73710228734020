import React, { useEffect, useState } from "react";
import { AsinService } from "../services/AsinService.ts";
import { Button, Input, Modal, Table, Tooltip, notification } from "antd";
import { Link, useLocation } from "wouter";
import { WarehouseService } from "../services/WarehouseService.ts";
import { RightLayout } from "../components/RightLayout.tsx";
import { StockSummary } from "../components/StockSummary.tsx";
import { ProductShortcut } from "../components/ProductShortcut.tsx";

const asinService = new AsinService();

export const AsinsPage = () => {
  const [favorites, setFavorites] = useState([]);
  const [allAsin, setAllAsins] = useState([]);
  const [filteredAsins, setFilteredAsins] = useState([]);

  const [asinText, setAsinText] = useState("");
  const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [location, navigate] = useLocation();

  useEffect(() => {
    refreshAsins();
    refreshWarehouses();
    const _favorites = localStorage.getItem("favorites");
    if (_favorites) {
      setFavorites(JSON.parse(_favorites));
    }
  }, []);

  const columns = [
    {
      title: "Imagen",
      dataIndex: "mainUrlImage",
      key: "mainUrlImage",
      render: (mainUrlImage: string, el: any) => {
        let style = {};
        if (
          el.internalNotes !== "" &&
          el.internalNotes !== null &&
          el.internalNotes !== undefined
        ) {
          style = {
            border: "1px solid crimson",
          };
        }
        return (
          <Tooltip
            title={() => (
              <div dangerouslySetInnerHTML={{ __html: el.internalNotes }}></div>
            )}
          >
            <img
              style={style}
              height={50}
              width={50}
              src={mainUrlImage}
              alt="product"
            />
          </Tooltip>
        );
      },
    },
    {
      title: "P.Base",
      dataIndex: "pBase",
      key: "pBase",
      render: (pBase: boolean, el: any) => {
        return (
          pBase && (
            <div
              style={{
                backgroundColor: "#52c41a",
                height: 20,
                width: 20,
                borderRadius: 33333,
              }}
            ></div>
          )
        );
      },
    },
    {
      title: "Asin",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "Titulo",
      dataIndex: "title",
      key: "title",
      width: "auto",
      render: (title: string, el: any) => {
        return (
          <Link to={`/products/${el.asin}`}>
            {title || "Cambia este titulo"}
          </Link>
        );
      },
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      render: (stock: any, el: any) => {
        return <StockSummary productId={el.asin} />;
      },
    },
    {
      title: "Fnsku",
      dataIndex: "fnsku",
      key: "fnsku",
    },
    {
      title: "Sku",
      dataIndex: "sku",
      key: "sku",
    },
  ];

  const refreshWarehouses = () => {
    WarehouseService.getAllWarehouses().then((res) => {
      const aux: any = {};
      res.data.forEach((el) => {
        aux[el._id] = el.name;
      });
      setWarehouseNames(aux);
    });
  };
  const refreshAsins = () => {
    asinService.getAllAsins().then((res) => {
      const aux = res.data.map((el) => ({
        key: el.asin,
        asin: el.asin,
        title: el.spanish?.title ?? "No titulo",
        stock: { stock: el.stock, warehouseStock: el.warehouseStock },
        mainUrlImage: el.spanish?.images?.MAIN ?? "./no-image.jpg",
        pBase: el.isBaseProduct ?? false,
        fnsku: el.fnsku,
        sku: el.sku,
        price: el.price,
        weight: el.weight,
        internalNotes: el.internalNotes,
      }));
      setFilteredAsins(aux);
      setAllAsins(aux);
    });
  };

  const handleNewProductModalOpen = () => {
    setIsNewProductModalOpen(true);
  };

  const handleNewProductModalClose = () => {
    setIsNewProductModalOpen(false);
  };

  const handleOk = () => {
    if (asinText.length > 0) {
      asinService.createAsin(asinText.trim()).then((res) => {
        notification.success({
          message: "Asin creado correctamente",
          description: res.data.message,
        });
        setIsNewProductModalOpen(false);
        setAsinText("");
        refreshAsins();
      });
    }
  };

  const handleInputChange = (e) => {
    setAsinText(e.target.value);
  };

  return (
    <RightLayout>
      <Modal
        title="Crear nuevo producto vacio"
        open={isNewProductModalOpen}
        onOk={handleOk}
        onCancel={handleNewProductModalClose}
      >
        <Input
          value={asinText}
          placeholder="Introduce el asin del producto"
          onChange={handleInputChange}
        />
      </Modal>

      <div
        className="dg-item"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Button
          style={{ marginRight: 10 }}
          type="primary"
          onClick={handleNewProductModalOpen}
        >
          Crear asin
        </Button>

        <div style={{ width: 200, height: 32 }}>
          <Input
            width={200}
            placeholder="input search text"
            onChange={(e) => {
              setFilteredAsins(
                allAsin.filter((el) => {
                  return (
                    el.asin
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    el.title
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  );
                })
              );
            }}
          />
        </div>
      </div>

      <Button
        className="dg-item"
        style={{ marginRight: 10 }}
        type="primary"
        onClick={() => {
          navigate("/check-all-errors");
        }}
      >
        Buscar errores en todos los productos
      </Button>

      <div className="dg-item d-flex" style={{ flexWrap: "wrap" }}>
        {favorites.map((asin) => (
          <div className="mr-12" key={asin}>
            <ProductShortcut asin={asin} />
          </div>
        ))}
      </div>

      <div id="asins-page-table" style={{ paddingBottom: 24 }}>
        <Table
          dataSource={filteredAsins}
          columns={columns}
          pagination={false}
        />
      </div>
    </RightLayout>
  );
};

import { Button, Input, notification } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "./ErrorMessage.tsx";
import { DGCKeditor } from "./DGCKeditor.tsx";
import { NoteService } from "../services/NoteService.ts";
import { WarningParagraph } from "./WarningParagraph.tsx";
import { useLocation } from "wouter";

interface NotesFormProps {
  noteId?: string;
}

export const NotesForm = ({ noteId }: NotesFormProps) => {
  const form = useForm();
  const {
    formState: { isDirty },
  } = form;
  const [location, setLocation] = useLocation();

  useEffect(() => {
    if (noteId) {
      NoteService.getNote(noteId).then((res) => {
        const note = res.data;
        form.reset({
          title: note.title,
          content: note.content,
        });
      });
    }
  }, [noteId]);

  const handleSaveUpdate = () => {
    const values = form.getValues();
    if (noteId) {
      // update
      NoteService.updateNote(noteId, values.title, values.content || "").then(
        (res) => {
          notification.success({
            message: "Nota actualizada",
            description: res.data.message,
          });
        }
      );
    } else {
      // create
      NoteService.createNote(values.title, values.content || "").then((res) => {
        notification.success({
          message: "Nota creada",
          description: res.data.message,
        });
        setLocation("/notes");
      });
    }
  };

  return (
    <div>
      {isDirty && (
        <div className="dg-item">
          <WarningParagraph text="Hay cambios" />
        </div>
      )}
      <div className="dg-item">
        <Controller
          name="title"
          control={form.control}
          rules={{ required: true }}
          render={({ field }) => {
            const handleAutoName = () => {
              form.setValue(
                "title",
                "Nota_" + dayjs().format("YYYY-MM-DD_HH:mm:ss")
              );
            };
            return (
              <>
                <div className="d-flex">
                  <Input {...field} placeholder="Introduce nombre de la nota" />
                  <Button type="primary" onClick={handleAutoName}>
                    Auto Nombre
                  </Button>
                </div>
                <ErrorMessage form={form} name="title" />
              </>
            );
          }}
        />
      </div>
      <div className="dg-item">
        <Controller
          name="content"
          control={form.control}
          rules={{ required: true }}
          render={({ field }) => {
            return <DGCKeditor {...field}></DGCKeditor>;
          }}
        />
      </div>
      <div
        className="dg-item"
        style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
      >
        <Button
          onClick={handleSaveUpdate}
          style={{ alignSelf: "flex-end" }}
          type="primary"
        >
          {noteId ? "Actualizar" : "Salvar"}
        </Button>
      </div>
    </div>
  );
};

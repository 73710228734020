import React, { useEffect, useState } from "react";
import { Alert, Card } from "antd";

const BoxSummary = ({ finalData, deliveryToAws }) => {
  const [discrepancies, setDiscrepancies] = useState([]);

  useEffect(() => {
    if (finalData) {
      checkDiscrepancies(finalData);
    }
  }, [finalData]);

  const checkDiscrepancies = (data) => {
    const totalQuantities = {};
    const discrepancies = [];

    data.forEach((box) => {
      for (const asin in box) {
        if (totalQuantities[asin]) {
          totalQuantities[asin] += parseInt(box[asin], 10);
        } else {
          totalQuantities[asin] = parseInt(box[asin], 10);
        }
      }
    });

    Object.keys(totalQuantities).forEach((asin) => {
      const expectedQuantity =
        deliveryToAws.asins.find((el) => el.item.asin === asin)?.item
          .finalQuantity || 0;
      if (totalQuantities[asin] !== expectedQuantity) {
        discrepancies.push({
          asin,
          expected: expectedQuantity,
          actual: totalQuantities[asin],
          product: deliveryToAws.asins.find((el) => el.item.asin === asin)
            ?.product,
        });
      }
    });

    setDiscrepancies(discrepancies);
  };

  const calculateBoxWeight = (box) => {
    return Object.keys(box).reduce((totalWeight, asin) => {
      const product = deliveryToAws.asins.find(
        (el) => el.item.asin === asin
      )?.product;
      const productWeight = product ? product.weight : 0;
      const quantity = parseInt(box[asin], 10);
      return totalWeight + productWeight * quantity;
    }, 0);
  };

  const calculateProductWeight = (asin, quantity) => {
    const product = deliveryToAws.asins.find(
      (el) => el.item.asin === asin
    )?.product;
    const productWeight = product ? product.weight : 0;
    return productWeight * quantity;
  };

  return (
    <div>
      <h3>Resumen de Cajas</h3>
      <div className="dg-item">
        {discrepancies.length > 0 && (
          <Alert
            message="Discrepancias detectadas"
            description={discrepancies.map((d) => (
              <div
                key={d.asin}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  alt={d.asin}
                  height={50}
                  width={50}
                  src={d.product?.spanish?.images?.MAIN || "./no-image.jpg"}
                  style={{ marginRight: "10px" }}
                />
                <p>{`Producto ASIN: ${d.asin}, Esperado: ${d.expected}, Actual: ${d.actual}`}</p>
              </div>
            ))}
            type="error"
          />
        )}
      </div>
      <div style={{ display: "flex", gap: "16px" }}>
        {finalData &&
          finalData.map((box, index) => {
            const boxWeight = calculateBoxWeight(box) / 1000;
            return (
              <Card
                key={index}
                title={`Caja ${index + 1} - Peso Total: ${boxWeight.toFixed(
                  2
                )} kg`}
                bordered
              >
                {Object.keys(box).map((asin) => {
                  const quantity = parseInt(box[asin], 10);
                  const product = deliveryToAws.asins.find(
                    (el) => el.item.asin === asin
                  )?.product;
                  const productWeight =
                    calculateProductWeight(asin, quantity) / 1000;
                  return (
                    <div
                      key={asin}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        alt={asin}
                        height={50}
                        width={50}
                        src={product?.spanish?.images?.MAIN || "./no-image.jpg"}
                        style={{ marginRight: "10px" }}
                      />
                      <p>{`ASIN: ${asin}, Unidades: ${quantity}, Peso: ${productWeight.toFixed(
                        2
                      )} kg`}</p>
                    </div>
                  );
                })}
              </Card>
            );
          })}
      </div>
    </div>
  );
};

export default BoxSummary;

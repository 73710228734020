import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { AsinService } from "../services/AsinService.ts";
import { StockSummary } from "./StockSummary.tsx";

interface ProductsTAbleProps {
  onSelect: (data: any[]) => void;
  showOnlyBaseProducts: boolean;
  showOnlyComposedProducts: boolean;
}

export const ProductsTable = ({
  onSelect,
  showOnlyBaseProducts,
  showOnlyComposedProducts,
}: ProductsTAbleProps) => {
  const [productList, setProductList] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const columns = [
    {
      title: "Imagen",
      dataIndex: "image",
      width: 100,
      render: (text, record, index) => {
        return (
          <img
            height={50}
            src={record.spanish?.images?.MAIN ?? "./no-image.jpg"}
          />
        );
      },
    },
    {
      title: "Stock",
      dataIndex: "stock",
      width: 100,
      render: (stock: any, el: any) => {
        return <StockSummary productId={el.asin} />;
      },
    },
    {
      title: "Asin",
      dataIndex: "asin",
      width: 100,
    },
    {
      title: "Titulo",
      dataIndex: "title",
      render: (text, record, index) => {
        return <span>{record.spanish.title}</span>;
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    onSelect(newSelectedRowKeys);
  };

  useEffect(() => {
    new AsinService().getAllAsins().then((products) => {
      let aux = products.data.map((product) => ({
        ...product,
        key: product.asin,
      }));

      if (showOnlyBaseProducts) {
        aux = aux.filter((product) => product.isBaseProduct === true);
      }
      if (showOnlyComposedProducts) {
        aux = aux.filter((product) => product.isBaseProduct === false);
      }

      setProductList(aux);
    });
  }, [showOnlyBaseProducts]);

  return (
    <Table
      pagination={false}
      rowSelection={{
        type: "checkbox",
        selectedRowKeys,
        onChange: onSelectChange,
      }}
      columns={columns}
      dataSource={productList}
    />
  );
};

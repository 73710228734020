import React, { useEffect, useState } from "react";
import { AsinService } from "../../services/AsinService.ts";
import {
  Button,
  Input,
  Popconfirm,
  Select,
  Skeleton,
  Tooltip,
  notification,
} from "antd";
import {
  DeleteOutlined,
  VerticalAlignMiddleOutlined,
  VerticalRightOutlined,
} from "@ant-design/icons";
import { Link } from "wouter";
import { TrimWithTooltip } from "../TrimWithTooltip.tsx";
import { StockSummary } from "../StockSummary.tsx";
import { LuClipboardCopy } from "react-icons/lu";

export const ProductRow = ({
  warehouseToRemoveStock,
  warehouses,
  mode,
  product,
  index,
  handleDeleteProduct,
  handleQuantityChange,
  handlePriceChange,
  handleShippingMethodChange,
  handleReceivedQuantity,
  isDirty,
}) => {
  const [productData, setProductData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [realQuantity, setRealQuantity] = useState(product.quantityConfirmed);
  const [warehouseId, setWarehouseId] = useState<any>(
    warehouseToRemoveStock || null
  );

  useEffect(() => {
    new AsinService()
      .getAsin(product.asin)
      .then((data) => {
        setProductData(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({
          message: "Error al obtener producto",
          description: error.message,
        });
      });
  }, [product.asin]);

  const handleGetPrice = (type) => {
    if (type === "last") {
      handlePriceChange(index, productData.price);
    } else if (type === "average") {
      handlePriceChange(index, productData.averagePrice);
    } else {
      console.error("error");
    }
  };

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="mr-12" style={{ cursor: "pointer" }}>
            {!product.quantityConfirmed && (
              <Popconfirm
                title="Borrar producto"
                description="Seguro que quieres borrar este producto del albaran?"
                onConfirm={() => handleDeleteProduct(index)}
                okText="Si"
                cancelText="No"
              >
                <DeleteOutlined style={{ color: "crimson" }} />
              </Popconfirm>
            )}
          </div>
          <div className="mr-12">
            <img
              alt="product"
              height={50}
              width={50}
              src={productData.spanish?.images?.MAIN ?? "./no-image.jpg"}
            />
          </div>
          <div className="mr-12" style={{ minWidth: 130 }}>
            <Link to={`/products/${product.asin}`}>{product.asin}</Link>
          </div>
          <div className="ml-12" style={{ minWidth: 30 }}>
            <StockSummary productId={product.asin} />
          </div>
          <div className="mr-12">
            <Input
              disabled={product.quantityConfirmed}
              value={product.quantity}
              title="Cantidad"
              placeholder="Cantidad"
              style={{ width: 65 }}
              onChange={(e) => {
                handleQuantityChange(index, e.target.value);
              }}
            />
          </div>

          <div className="mr-12">
            <Input
              disabled={
                product.quantityConfirmed || warehouseToRemoveStock !== "N/A"
              }
              value={product.price}
              title="Precio"
              placeholder="Precio"
              style={{ width: 65 }}
              onChange={(e) => {
                handlePriceChange(index, e.target.value);
              }}
            />
          </div>
          <div className="mr-12" style={{ width: 36 }}>
            <Tooltip title={`Coger ultimo precio (${productData?.price})`}>
              <VerticalRightOutlined
                onClick={() => handleGetPrice("last")}
                style={{ color: "#1890ff" }}
              />
            </Tooltip>
            <Tooltip
              title={`Coger precio medio (${productData?.averagePrice})`}
            >
              <VerticalAlignMiddleOutlined
                onClick={() => handleGetPrice("average")}
                style={{ color: "#1890ff" }}
              />
            </Tooltip>
          </div>
          <div className="mr-12">
            <Select
              disabled={
                product.quantityConfirmed || warehouseToRemoveStock !== "N/A"
              }
              value={product.shippingMethod}
              onChange={(value) => {
                handleShippingMethodChange(index, value);
              }}
              style={{ width: 100 }}
              options={[
                { value: "boat", label: <span>Barco</span> },
                { value: "train", label: <span>Tren</span> },
                { value: "fly", label: <span>Avion</span> },
              ]}
            />
          </div>
          <div className="mr-12" style={{ width: 300 }}>
            <Link
              to={`/products/${product.asin}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TrimWithTooltip text={productData.spanish.title} />
            </Link>
          </div>
          {mode === "edit" && (
            <div className="mr-12" style={{ minWidth: 285 }}>
              <div className="d-flex">
                <div
                  className="d-flex-center"
                  onClick={() => {
                    setRealQuantity(product.quantity);
                  }}
                >
                  <Tooltip title="Click para copiar cantidad recibida real">
                    <LuClipboardCopy style={{ color: "rgb(24, 144, 255)" }} />
                  </Tooltip>
                </div>
                <Input
                  disabled={product.quantityConfirmed}
                  value={realQuantity || ""}
                  className="mr-12"
                  style={{ width: 65 }}
                  title="Cantidad recibida real"
                  placeholder="Recibido real"
                  onChange={(e) => {
                    setRealQuantity(e.target.value);
                  }}
                />
                <Select
                  className="mr-12"
                  style={{ width: 100 }}
                  value={product?.warehouseId || warehouseId}
                  onChange={(value) => {
                    setWarehouseId(value);
                  }}
                  disabled={product.quantityConfirmed}
                  options={warehouses.map((el) => ({
                    value: el._id,
                    label: el.name,
                  }))}
                />

                {!product.quantityConfirmed && (
                  <Popconfirm
                    title="Confirmar"
                    description={`Confirmar la cantidad recibida (${realQuantity})`}
                    onConfirm={() =>
                      handleReceivedQuantity(product, realQuantity, warehouseId)
                    }
                    okText="Si"
                    cancelText="No"
                  >
                    <Button
                      disabled={!realQuantity || !warehouseId || isDirty}
                      type="primary"
                    >
                      Confirmar
                    </Button>
                  </Popconfirm>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

import axios from "axios";
import { sleep } from "../utils/utils.ts";

export class SpApiService {
  static getTodayOrders = async () => {
    return axios.get("/sp-api/check-bullets-and-keywords");
  };

  static getAwsProductAttributes = async (sku: string, marketplace: string) => {
    return axios.get(
      `/sp-api/get-aws-product-attributes/${sku}/${marketplace}`
    );
  };

  static putAwsProductAttributes = async (sku: string, marketplace: string) => {
    return axios.put(
      `/sp-api/put-aws-product-attributes/${sku}/${marketplace}`
    );
  };

  static putAwsProductAttributesTest = async (
    sku: string,
    marketplace: string
  ) => {
    return axios.put(
      `/sp-api/put-aws-product-attributes/test/${sku}/${marketplace}`
    );
  };

  static getProductTypesOfWordOfMarketplace = async ({
    word,
    lang,
    marketplaceId,
  }: {
    word: string;
    lang: string;
    marketplaceId;
  }) => {
    return axios.post(`/sp-api/get-product-types-of-word`, {
      word,
      lang,
      marketplaceId,
    });
  };

  static getTypeDefinitionsOfProductType = async ({
    productType,
    marketplaceId,
  }: {
    productType: string;
    marketplaceId;
  }) => {
    return axios.post(`/sp-api/get-type-definitions-of-product-type`, {
      productType,
      marketplaceId,
    });
  };

  static deleteListing = async ({
    sku,
    marketplaceId,
  }: {
    sku: string;
    marketplaceId: string;
  }) => {
    return axios.delete(`/sp-api/delete-listing/${sku}/${marketplaceId}`);
  };

  static getListingInfo = async ({
    asin,
    marketplaceId,
  }: {
    asin: string;
    marketplaceId: string;
  }) => {
    return axios.get(`/sp-api/get-listing-info/${asin}/${marketplaceId}`);
  };

  static patchListing = async ({
    asin,
    marketplaceId,
    body,
    isValidationMode,
  }: {
    asin: string;
    marketplaceId: string;
    body: any;
    isValidationMode: boolean;
  }) => {
    return axios.patch(`/sp-api/patch-listing/${asin}/${marketplaceId}`, {
      body: {
        productType: body.productType,
        patches: body.patches,
        isValidationMode: isValidationMode,
      },
    });
  };

  static searchErrorsOnAllProducts = async () => {
    return axios.get("/sp-api/search-errors-on-all-products");
  };

  static getOrders = async ({
    fromDate,
    toDate,
    marketplaceIds,
  }: {
    fromDate: string;
    toDate: string;
    marketplaceIds: string;
  }) => {
    const result = await axios.post(`/sp-api/get-aws-orders`, {
      fromDate,
      toDate,
      marketplaceIds,
    });

    const orders = result.data.payload.Orders;

    for (let index = 0; index < orders.length; index++) {
      await sleep(800);
      const element = orders[index];
      const awsOrderItems = await axios.post(
        "/sp-api/get-aws-orders/order-items",
        {
          orderId: element.AmazonOrderId,
        }
      );
      element.OrderItems = awsOrderItems.data.payload.OrderItems;
      orders[index] = element;
    }

    return orders;
  };
}

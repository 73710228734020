// src/pages/ProductDetailsPage.tsx

import React, { useEffect, useMemo } from "react";
import { AsinService } from "../services/AsinService.ts";
import { ProductForm } from "../components/ProductForm.tsx";
import { useLocation } from "wouter";
import { RightLayout } from "../components/RightLayout.tsx";

export const ProductDetailsPage = ({ asin }) => {
  const asinService = useMemo(() => new AsinService(), []);
  const [location, setLocation] = useLocation();

  const handleOnDeleteAsin = async () => {};

  const handleOnBack = async () => {
    setLocation("/products");
  };

  return (
    <ProductForm
      asin={asin}
      asinService={asinService}
      onDeleteAsin={handleOnDeleteAsin}
      onBack={handleOnBack}
    />
  );
};

import { Modal } from "antd";
import React, { useState } from "react";
import { ProductsTable } from "./ProductsTable.tsx";

interface ProductListModalSelectableProps {
  showOnlyBaseProducts: boolean;
  showOnlyComposedProducts: boolean;
  onProductsSelected?: (data: any[]) => void;
  handleOk: (data: any[]) => void;
  handleCancel: () => void;
}

export const ProductListModalSelectable = ({
  onProductsSelected,
  handleOk,
  handleCancel,
  showOnlyBaseProducts,
  showOnlyComposedProducts,
}: ProductListModalSelectableProps) => {
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

  const onSelect = (data) => {
    setSelectedProducts(data);
    onProductsSelected?.(data);
  };

  const onOk = () => {
    handleOk(selectedProducts);
  };

  return (
    <>
      <Modal
        width={"80vw"}
        centered
        title="Selector de productos"
        open={true}
        onOk={onOk}
        onCancel={handleCancel}
      >
        <ProductsTable
          onSelect={onSelect}
          showOnlyBaseProducts={showOnlyBaseProducts}
          showOnlyComposedProducts={showOnlyComposedProducts}
        />
      </Modal>
    </>
  );
};

import React, { useContext } from "react";
import { DataContext } from "../context/DataContext.tsx";
import { Link } from "wouter";

export const ProductShortcut = ({ asin }) => {
  const dataContext = useContext(DataContext);
  const product = dataContext.allProducts[asin];
  return (
    <Link to={`/products/${asin}`}>
      <img
        style={{
          borderRadius: 10,
          border: "1px solid #ccc",
        }}
        height={50}
        width={50}
        src={product?.spanish?.images?.MAIN}
        alt={asin}
      />
    </Link>
  );
};

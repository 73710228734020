import React, { useEffect, useState } from "react";
import { OrderService } from "../services/OrderService.ts";
import { Link } from "wouter";
import { Popconfirm, Table, Tooltip, notification } from "antd";
import dayjs from "dayjs";
import { DeleteOutlined, LinkOutlined } from "@ant-design/icons";

export const EntryOrdersList = ({ showCompleted }) => {
  const [orders, setOrders] = useState<any>([]);

  const columns = [
    {
      title: "Titulo",
      dataIndex: "title",
      key: "title",
      render: (title: string, order: any) => {
        return <Link to={`/entry-orders/${order.key}`}>{title}</Link>;
      },
    },
    {
      title: "Comentarios",
      dataIndex: "comments",
      key: "comments",
    },
    { title: "Tracking", dataIndex: "trackingLinks", key: "trackingLinks" },
    { title: "AlibabaLink", dataIndex: "alibabaLink", key: "alibabaLink" },
    {
      title: <Tooltip title="Dias pasados desde envio">Días</Tooltip>,
      dataIndex: "daysPassedSinceOrderDate",
      key: "daysPassedSinceOrderDate",
      render: (daysPassedSinceOrderDate: any, order: any) => {
        return daysPassedSinceOrderDate;
      },
    },

    {
      title: "Fecha envío",
      dataIndex: "orderSentDate",
      key: "orderSentDate",
    },
    {
      title: "Productos",
      dataIndex: "products",
      key: "products",
      render: (products: any, order: any) => {
        return (
          <div>
            {products.map((product: any, index) => (
              <img
                key={`${product}-${index}`}
                height={25}
                width={25}
                src={product}
                alt="product"
              />
            ))}
          </div>
        );
      },
    },
    {
      title: "Borrar",
      dataIndex: "delete",
      key: "delete",
      render: (line: any, order: any) => {
        const handleDeleteConfirm = (data) => {
          new OrderService().deleteOrder(order.key).then(() => {
            notification.success({
              message: "Su pedido ha sido borrado",
              description: "",
            });
            refreshOrders();
          });
        };
        return (
          <div style={{ cursor: "pointer" }}>
            <Popconfirm
              title="¿Estás seguro que quieres borrar este pedido?"
              onConfirm={handleDeleteConfirm}
            >
              <DeleteOutlined style={{ color: "crimson" }} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    refreshOrders();
  }, [showCompleted]);

  const refreshOrders = async () => {
    new OrderService().getOrders().then((data) => {
      let aux = [];
      if (showCompleted) {
        aux = data.data.filter((order) => order.completed);
      } else {
        aux = data.data.filter(
          (order) => order.completed === false || !order.completed
        );
      }

      setOrders(aux);
    });
  };

  const dataSource = orders.map((order) => {
    const dateObject = dayjs(order.orderSentDate);
    const today = dayjs();
    const differenceInDays = today.diff(dateObject, "day");

    const images = order.products.map((product) => {
      return product.mainImage;
    });

    return {
      key: order._id,
      title: order.title,
      comments: order.comments,
      trackingLinks: order.trackingNumbers.map((trackingNumber) => {
        return (
          <a
            href={`https://parcelsapp.com/es/tracking/${trackingNumber.number}`}
            target="_blank"
            rel="noreferrer"
          >
            <LinkOutlined style={{ color: "#007bff" }} />
          </a>
        );
      }),
      orderDate: order.orderDate?.substr(0, 10),
      orderSentDate: order.orderSentDate?.substr(0, 10),
      products: images,
      daysPassedSinceOrderDate: isNaN(differenceInDays) ? "" : differenceInDays,
      alibabaLink: (
        <a href={order.alibabaLink} target="_blank" rel="noreferrer">
          <LinkOutlined style={{ color: "#007bff" }} />
        </a>
      ),
    };
  });

  return (
    <div>
      <Table pagination={false} dataSource={dataSource} columns={columns} />
    </div>
  );
};

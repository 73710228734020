import React, { useEffect } from "react";
import { RightLayout } from "../components/RightLayout.tsx";
import { Button, Popconfirm, Table, notification } from "antd";
import { Link, useLocation } from "wouter";
import { NoteService } from "../services/NoteService.ts";
import { DeleteOutlined } from "@ant-design/icons";

export const NotesPage = () => {
  const [location, setLocation] = useLocation();
  const [notes, setNotes] = React.useState<any[]>([]);

  const columns = [
    {
      title: "Borrar",
      key: "delete",
      render: (text, record) => {
        const handleDeleteConfirm = () => {
          NoteService.deleteNote(record._id).then((res) => {
            notification.success({
              message: "Nota eliminada",
              description: res.data.message,
            });
            refresh();
          });
        };
        return (
          <Popconfirm
            title="¿Estás seguro que quieres borrar esta nota?"
            onConfirm={handleDeleteConfirm}
          >
            <DeleteOutlined style={{ color: "crimson" }} />
          </Popconfirm>
        );
      },
    },
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Fecha de creación",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Fecha de modificación",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "",
      key: "action",
      render: (text, record) => {
        return (
          <Link href={`/note/${record._id}`}>
            <Button type="primary">Ver nota</Button>
          </Link>
        );
      },
    },
  ];
  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    NoteService.getNotes().then((res) => {
      setNotes(res.data);
    });
  };

  const handleNewNote = () => {
    setLocation("/new-note");
  };

  return (
    <RightLayout>
      <div className="dg-item">
        <Button onClick={handleNewNote} type="primary">
          Crear nota
        </Button>
      </div>
      <Table columns={columns} dataSource={notes} pagination={false}></Table>
    </RightLayout>
  );
};

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import axios from "axios";
import { API_URL } from "./config.ts";
import { NotificationService } from "./services/NotificationService.ts";
import { ConfigProvider } from "antd";
import { DataContextProvider } from "./context/DataContext.tsx";
import { HiddenTraslator } from "./components/HiddenTraslator.tsx";

axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    NotificationService.error("Error", error.response.data.msg);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    theme={{
      token: {
        // color one #000022
        // color two #E9E9E9
        // color three #AF3800
        // color four #2176AE
        // https://ant.design/docs/react/customize-theme#customize-component-token
        colorPrimary: "#000022",
        colorPrimaryBgHover: "#E9E9E9",
        // colorPrimaryBg: 'green',
        // colorPrimaryActive: 'green'
      },
    }}
  >
    <DataContextProvider>
      <HiddenTraslator />
      <App />
    </DataContextProvider>
  </ConfigProvider>
);

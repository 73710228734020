import React, { useEffect, useMemo, useState } from "react";
import { AsinService } from "../services/AsinService.ts";
import { Table } from "antd";
import { WarehouseService } from "../services/WarehouseService.ts";
import { Link } from "wouter";

const asinService = new AsinService();
export const ProductTableFromAsinArray = ({ asins }) => {
  const [allProducts, setAllProducts] = useState({});
  const [allWarehouses, setAllWarehouses] = useState({});

  useEffect(() => {}, []);

  useEffect(() => {
    const fetchAsins = async () => {
      try {
        WarehouseService.getAllWarehouses().then(async (res) => {
          const aux = res.data.reduce((acc, warehouse) => {
            acc[warehouse._id] = warehouse;
            return acc;
          }, {});

          setAllWarehouses(aux);
          // Filtra los ASINs que ya están en allProducts
          const asinsToFetch = asins.filter((asin) => !allProducts[asin]);
          if (asinsToFetch.length > 0) {
            const responses = await Promise.all(
              asinsToFetch.map((asin) => asinService.getAsin(asin))
            );
            const newProducts = {};
            responses.forEach((res, index) => {
              newProducts[asinsToFetch[index]] = res.data;
            });
            setAllProducts((prevProducts) => ({
              ...prevProducts,
              ...newProducts,
            }));
          }
        });
      } catch (error) {
        console.error("Error fetching ASINs:", error);
      }
    };

    fetchAsins();
  }, [asins]);

  const columns = useMemo(() => {
    return [
      {
        title: "ASIN",
        dataIndex: "asin",
        key: "asin",
      },
      {
        title: "Titulo",
        dataIndex: "title",
        key: "title",
        render: (title, element) => (
          <Link to={`/products/${element.asin}`}>{title}</Link>
        ),
      },
      {
        title: "Ultimo precio",
        dataIndex: "price",
        key: "price",
      },
      {
        title: "Precio promedio",
        dataIndex: "averagePrice",
        key: "averagePrice",
      },
      {
        title: "Stock",
        dataIndex: "stock",
        key: "stock",
        width: 200,
        render: (stock) => {
          return (
            <div>
              <span style={{ fontWeight: "bold" }}>Total:</span> {stock.stock}
              {stock.warehouseStock?.map((warehouseStock) => {
                return (
                  <div key={warehouseStock.warehouse}>
                    <span style={{ fontWeight: "bold" }}>
                      {warehouseStock.warehouseName}
                    </span>
                    : {warehouseStock.stock}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        render: (image) => (
          <img
            src={image}
            style={{ width: "100px", height: "100px" }}
            alt={image}
          />
        ),
      },
    ];
  }, []);

  const dataSource = useMemo(() => {
    return asins.map((el, index) => {
      return {
        key: index,
        asin: el,
        title: allProducts[el]?.spanish?.title,
        price: allProducts[el]?.price,
        averagePrice: allProducts[el]?.averagePrice,
        image: allProducts[el]?.spanish?.images?.MAIN,
        stock: {
          stock: allProducts[el]?.stock,
          warehouseStock: allProducts[el]?.warehouseStock.map((result) => ({
            ...result,
            warehouseName: allWarehouses?.[result.warehouse]?.name,
          })),
        },
      };
    });
  }, [asins, allProducts, allWarehouses]);

  return (
    <div>
      <div>
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </div>
    </div>
  );
};

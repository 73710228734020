import React, { useContext, useEffect } from "react";
import { OrderService } from "../services/OrderService.ts";
import { Link, useParams } from "wouter";
import { DataContext } from "../context/DataContext.tsx";
import { Table } from "antd";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (title: string, order: any) => {
      return <Link to={`/entry-orders/${order.key}`}>{title}</Link>;
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Date",
    dataIndex: "orderDate",
    key: "orderDate",
  },
  {
    title: "Alibaba link",
    dataIndex: "alibabaLink",
    key: "alibabaLink",
    render: (alibabaLink: string, order: any) => {
      return (
        <>
          {alibabaLink && (
            <a href={alibabaLink} target="_blank" rel="noreferrer">
              Ver en Alibaba
            </a>
          )}
        </>
      );
    },
  },
];
const ProductsBuysCalculator = () => {
  const { asin, date } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [orders, setOrders] = React.useState<any>([]);
  const { allProducts } = useContext(DataContext);
  const [totalQuantity, setTotalQuantity] = React.useState(0);

  useEffect(() => {
    if (!date || !asin) return;

    setIsLoading(true);
    new OrderService()
      .calculateUnitsSinceDate({
        asin,
        date,
      })
      .then((res) => {
        setOrders(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [asin, date]);

  useEffect(() => {
    const _totalQuantity = orders.reduce((acc, order) => {
      const matchingProducts = order.products.filter(
        (product) => product.asin === asin
      );
      const quantitySum = matchingProducts.reduce(
        (sum, product) => sum + product.quantity,
        0
      );
      return acc + quantitySum;
    }, 0);
    setTotalQuantity(_totalQuantity);
  }, [orders]);

  return (
    <div>
      <h1>ProductsBuysCalculator</h1>
      <div className="d-flex-center">
        <img
          height={100}
          width={100}
          src={allProducts[asin]?.spanish?.images?.MAIN}
          alt={asin}
          style={{
            borderRadius: 10,
            border: "1px solid #ccc",
            marginRight: 20,
          }}
        />
        <h2>{allProducts[asin]?.spanish?.title}</h2>
        <div
          style={{
            minWidth: 100,
            minHeight: 100,
            borderRadius: 10,
            border: "1px solid #ccc",
            marginLeft: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3>Total</h3>
          <div>{totalQuantity}</div>
        </div>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <h2>Orders</h2>
          <Table
            dataSource={orders.map((el) => {
              const buys = el.products.filter(
                (product) => product.asin === asin
              );
              let quantity = 0;
              for (const buy of buys) {
                quantity += buy.quantity;
              }

              return {
                key: el._id,
                title: el.title,
                quantity: quantity,
                orderDate: el.orderDate.substr(0, 10),
                alibabaLink: el.alibabaLink,
              };
            })}
            columns={columns}
            pagination={false}
          />
        </div>
      )}
    </div>
  );
};

export default ProductsBuysCalculator;

import { Button, Input, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import { ProductListModalSelectable } from "../components/ProductListModalSelectable.tsx";
import { AsinService } from "../services/AsinService.ts";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "../components/ErrorMessage.tsx";
import { DeliveryToAwsService } from "../services/DeliveryToAwsService.ts";
import { useLocation } from "wouter";
import { RightLayout } from "../components/RightLayout.tsx";
import dayjs from "dayjs";

export const GenerateDeliveryToAwsPageNew = () => {
  const form = useForm({
    defaultValues: {},
    mode: "all",
  });

  const [productList, setProductList] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [confirmedProducts, setConfirmedProducts] = useState<React.Key[]>([]);
  const [location, setLocation] = useLocation();
  const columns = [
    {
      title: "Imagen",
      dataIndex: "image",
      width: 100,
      render: (text, record, index) => {
        return (
          <img
            height={50}
            src={record.spanish?.images?.MAIN ?? "./no-image.jpg"}
          />
        );
      },
    },
    {
      title: "Cantidad a enviar",
      dataIndex: "quantity",
      width: 100,
      render: (text, record, index) => {
        return (
          <Controller
            key={record.asin}
            name={`asins.${record.asin}`}
            control={form.control}
            render={({ field }) => (
              <>
                <Input {...field} placeholder="Cantidad" />
                <ErrorMessage form={form} name={`${record.asin}`} />
              </>
            )}
          />
        );
      },
    },
    {
      title: "Stock",
      dataIndex: "stock",
      width: 100,
    },
    {
      title: "Asin",
      dataIndex: "asin",
      width: 100,
    },
    {
      title: "Titulo",
      dataIndex: "title",
      render: (text, record, index) => {
        return <span>{record.spanish.title}</span>;
      },
    },
  ];

  useEffect(() => {
    new AsinService().getAllAsins().then((products) => {
      setProductList(products.data);
    });
  }, []);

  const handleProductsSelected = (products) => {
    setSelectedRowKeys(products);
  };

  const handleOk = () => {
    setShowModal(false);
    setConfirmedProducts(
      productList.filter((p) => selectedRowKeys.includes(p.asin))
    );
  };

  const handleConfirm = () => {
    DeliveryToAwsService.createDeliveryToAws(form.getValues())
      .then((res) => {
        notification.success({
          message: "Envío a AWS creado",
          description: "El envío a AWS se ha creado correctamente",
        });
        setLocation("/generate-delivery-to-aws");
      })
      .catch((err) => {
        notification.error({
          message: "Error al crear el envío a AWS",
          description: err.message,
        });
      });
  };

  return (
    <RightLayout>
      {showModal && (
        <ProductListModalSelectable
          showOnlyBaseProducts={false}
          onProductsSelected={handleProductsSelected}
          handleOk={handleOk}
          handleCancel={() => setShowModal(false)}
        />
      )}
      <Button
        style={{ marginBottom: 24 }}
        type="primary"
        onClick={() => setShowModal(true)}
      >
        Añadir producto
      </Button>

      {confirmedProducts.length > 0 && (
        <>
          <Controller
            name="title"
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => {
              const handleAutoName = () => {
                form.setValue(
                  "title",
                  "Envio_AWS_" + dayjs().format("YYYY-MM-DD_HH:mm:ss")
                );
              };
              return (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Input
                      {...field}
                      style={{ marginBottom: 24 }}
                      placeholder="Nombre de envio"
                    />
                    <Button type="primary" onClick={handleAutoName}>
                      Auto Nombre
                    </Button>
                  </div>
                  <ErrorMessage form={form} name="title" />
                </>
              );
            }}
          />

          <Table
            pagination={false}
            columns={columns}
            dataSource={confirmedProducts}
            footer={() => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Total: {confirmedProducts.length}</span>
                <div>
                  <Button
                    danger
                    style={{ marginRight: 24 }}
                    onClick={() => setConfirmedProducts([])}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={!form.formState.isValid}
                    type="primary"
                    onClick={handleConfirm}
                  >
                    Confirmar
                  </Button>
                </div>
              </div>
            )}
          />
        </>
      )}
    </RightLayout>
  );
};

import axios from "axios";

export class ChatGptPromptService {
  static async getChatGptPrompts() {
    return axios.get("/chat-gpt-prompts");
  }

  static async getChatGptPrompt(id: string) {
    return axios.get(`/chat-gpt-prompts/${id}`);
  }

  static async createChatGptPrompt(prompt: any) {
    return axios.post("/chat-gpt-prompts", prompt);
  }

  static async updateChatGptPrompt(prompt: any) {
    return axios.put(`/chat-gpt-prompts/${prompt._id}`, prompt);
  }

  static async deleteChatGptPrompt(id: string) {
    return axios.delete(`/chat-gpt-prompts/${id}`);
  }

  static async addOnePrompt(id: string, prompt: any) {
    return axios.put(`/chat-gpt-prompts/${id}/add-one-prompt`, prompt);
  }
}

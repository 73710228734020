import axios from "axios";

export class ParentProductServices {
  static async getAllParentProducts() {
    return axios.get("/parent-products");
  }

  static async createParentProduct(parentProduct: any) {
    return axios.post("/parent-products", parentProduct);
  }

  static async getParentProduct(asin: string) {
    return axios.get(`/parent-products/${asin}`);
  }

  static async updateParentProduct(parentProduct: any) {
    return axios.patch(
      `/parent-products/${parentProduct.originalAsin}`,
      parentProduct
    );
  }

  static async deleteParentProduct(id: string) {
    return axios.delete(`/parent-products/${id}`);
  }
}

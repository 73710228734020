import {
  Button,
  FloatButton,
  Modal,
  notification,
  Select,
  Spin,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useContext, useState } from "react";
import { ChatService } from "../services/ChatService.ts";
import { LoadingOutlined } from "@ant-design/icons";
import { DataContext } from "../context/DataContext.tsx";

const { Option } = Select;

export const HiddenTraslator = () => {
  const [translatedText, setTranslatedText] = useState<any>({
    response: "",
    responseOrigin: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("de");
  const [isLoading, setIsLoading] = useState(false);
  const { chatGptPrompts } = useContext(DataContext);

  const getPrompt = () => {
    const languages = {
      de: "Alemán",
      fr: "Francés",
      it: "Italiano",
      en: "Inglés",
      nl: "neerlandes",
      pl: "polaco",
      se: "sueco",
      es: "español",
    };
    let result = "";
    if (chatGptPrompts["translate-hidden-component"]) {
      const lastItem =
        chatGptPrompts["translate-hidden-component"].prompt.length - 1;
      let prePrompt = "";
      if (chatGptPrompts["translate-hidden-component"].prompt?.length > 0) {
        prePrompt =
          chatGptPrompts["translate-hidden-component"].prompt[lastItem].text;
      }
      let finalPrompt = prePrompt.replace(
        "{{targetLanguage}}",
        languages[targetLanguage]
      );
      result = finalPrompt.replace("{{selectedText}}", selectedText);
    }
    return result;
  };

  const showModal = () => {
    setTranslatedText({
      response: "",
      responseOrigin: "",
    });
    setIsModalVisible(false);
    setTargetLanguage("de");
    setIsModalVisible(true);
  };

  const handleOk = async ({ force = false }) => {
    setTranslatedText({
      response: "",
      responseOrigin: "",
    });
    setIsLoading(true);

    const finalPrompt = getPrompt();

    try {
      const res2 = await ChatService.askToChat({
        message: finalPrompt,
        force,
      });

      setTranslatedText(res2?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleLanguageChange = (value) => {
    setTargetLanguage(value);
  };

  const handleTranslatorClick = () => {
    const text = window.getSelection()?.toString();
    if (text) {
      setSelectedText(text);
      showModal();
    } else {
      notification.error({
        message: "No Text Selected",
        description: "Please select some text to translate.",
      });
    }
  };

  return (
    <div>
      <FloatButton onClick={handleTranslatorClick} type="primary" />
      <Modal
        width={"70vw"}
        title="Traductor de textos"
        destroyOnClose={true}
        open={isModalVisible}
        okText={
          isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 16, color: "white" }}
                  spin
                />
              }
            />
          ) : (
            "Traducir"
          )
        }
        onOk={() => handleOk({ force: false })}
        onCancel={handleCancel}
      >
        <p>
          <Tooltip overlayStyle={{ maxWidth: "650px" }} title={selectedText}>
            {selectedText.substring(0, 50)}
          </Tooltip>{" "}
        </p>
        <div className="d-flex-center">
          <Select
            defaultValue="de"
            style={{ width: 120 }}
            onChange={handleLanguageChange}
          >
            <Option value="de">Aleman</Option>
            <Option value="fr">Frances</Option>
            <Option value="it">Italiano</Option>
            <Option value="nl">Neerlandes</Option>
            <Option value="pl">Polaco</Option>
            <Option value="en">Ingles</Option>
            <Option value="se">Sueco</Option>
            <Option value="es">Español</Option>
          </Select>
        </div>
        <p>
          <b>Prompt: </b>
          <span style={{ color: "blueviolet" }}>{getPrompt()}</span>
        </p>
        <p>
          <div className="d-flex-center">
            Texto traducido de{" "}
            <span style={{ color: "crimson", marginLeft: 4 }}>
              {translatedText?.responseOrigin}
            </span>
            <span style={{ marginLeft: "auto" }}>
              <Button
                disabled={isLoading}
                onClick={() => handleOk({ force: true })}
              >
                Forzar
              </Button>
            </span>
          </div>
        </p>
        <div className="dg-item">
          <TextArea value={translatedText?.response} rows={5}></TextArea>
        </div>
      </Modal>
    </div>
  );
};

import React, { useContext } from "react";

import "./RightLayout.scss";
import { FaBell } from "react-icons/fa";
import { DataContext } from "../context/DataContext.tsx";
import { Badge } from "antd";

export const RightLayout = ({ children }) => {
  const { notifications } = useContext(DataContext);
  return (
    <div id="right-layout-container" className="right-layout-container">
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1 1 auto" }}></div>
        <Badge size="small" count={5}>
          <FaBell style={{ marginLeft: "auto" }} />
        </Badge>
      </div>
      <>{children}</>
    </div>
  );
};

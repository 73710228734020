import { Progress } from "antd";
import React from "react";

interface CharCountBarProps {
  successLength: number;
  valueLength: number;
}

export const CharCountBar = ({
  successLength,
  valueLength,
}: CharCountBarProps) => {
  const percent = (valueLength / successLength) * 100;

  let bar;

  if (percent < 50) {
    bar = <Progress percent={percent} status="exception" />;
  } else if (percent < 70) {
    bar = <Progress percent={percent} />;
  } else if (percent < 85) {
    bar = <Progress percent={percent} />;
  } else if (percent <= 100) {
    bar = <Progress percent={percent} status="success" />;
  } else if (percent > 100) {
    bar = <Progress percent={percent} status="exception" />;
  }
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ fontSize: 12, color: "#666", marginRight: 10 }}>
          {valueLength}/{successLength}
        </span>
        {bar}
      </div>
      {valueLength > successLength && (
        <div style={{ fontSize: 12, color: "crimson", marginBottom: 10 }}>
          La longitud máxima de palabras claves es de {successLength} caracteres
        </div>
      )}
    </>
  );
};

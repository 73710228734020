import axios from "axios";

export class WarehouseService {
  public static getAllWarehouses(): Promise<any> {
    return axios.get("/warehouses");
  }

  public static createWarehouse(warehouse: any): Promise<any> {
    return axios.post("/warehouses", warehouse);
  }
}

import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./ImageUploader.scss";
import { DeleteOutlined } from "@ant-design/icons";
import { notification } from "antd";

interface ImageUploaderProps {
  originalImageUrl?: string;
  type: "MAIN" | "PT01" | "PT02" | "PT03" | "PT04" | "PT05" | "PT06";
  onImageChange: (imageUrl: string) => void;
  onImageDelete: (type: string) => void;
}

const ImageUploader = ({
  originalImageUrl = "",
  type,
  onImageChange,
  onImageDelete,
}: ImageUploaderProps) => {
  const [imageUrl, setImageUrl] = useState(originalImageUrl ?? "");
  const [showLargeImage, setShowLargeImage] = useState(false);

  React.useEffect(() => {
    if (originalImageUrl) {
      setImageUrl(originalImageUrl);
    }
  }, [originalImageUrl]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file.type !== "image/png") {
        notification.error({
          message: "File type not supported",
          description: "Please upload a PNG file",
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
        onImageChange(file);
      };
      reader.readAsDataURL(file);
    },
    [onImageChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
    },
  });

  const handleImageClick = () => {
    setShowLargeImage(!showLargeImage);
  };

  const handleDeleteImage = () => {
    setImageUrl("");
    onImageDelete(type);
  };

  return (
    <div className="image-uploader">
      {type}
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {imageUrl ? (
          <img
            width={150}
            height={150}
            src={imageUrl}
            alt="Preview"
            className={"myimage"}
            onClick={handleImageClick}
          />
        ) : (
          <p>Drag 'n' drop an image here, or click to select one</p>
        )}
      </div>

      <button onClick={handleDeleteImage}>
        <DeleteOutlined />
      </button>
    </div>
  );
};

export default ImageUploader;

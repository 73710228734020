import { notification } from "antd";

const success = (message: string, description: string) => {
  notification.success({
    message: message || "",
    description: description || "",
    placement: "bottomRight",
    duration: 3,
  });
};

const error = (message: string, description: string) => {
  notification.error({
    message: message || "",
    description: description || "",
    placement: "bottomRight",
    duration: 3,
  });
};

export const NotificationService = {
  success,
  error,
};

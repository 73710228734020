import React from "react";
import Iframe from "react-iframe";
import { CALENDAR_EMAIL } from "../config.ts";

export const CalendarPage = () => {
  return (
    <Iframe
      url={`https://calendar.google.com/calendar/embed?src=${CALENDAR_EMAIL}&ctz=Europe%2FMadrid`}
      width="99%"
      height="99%"
      id=""
      className=""
      display="block"
      position="relative"
    />
  );
};

import React, { useContext, useEffect, useState } from "react";
import "./OrderPage.scss";
import { Button, DatePicker, Spin, Table } from "antd";
import { Controller, set, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { SpApiService } from "../services/SpApiService.ts";
import { MARKETPLACES_IDS } from "../config.ts";
import { Flag } from "../components/flags/Flag.tsx";
import { DataContext } from "../context/DataContext.tsx";
import { NotificationService } from "../services/NotificationService.ts";
import { Link } from "wouter";

const columns = [
  {
    title: "País",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "AmazonOrderId",
    dataIndex: "AmazonOrderId",
    key: "AmazonOrderId",
    render: (AmazonOrderId: string, el: any) => {
      return (
        <a
          href={`https://sellercentral.amazon.es/orders-v3/order/${AmazonOrderId}`}
          target="_blank"
          rel="noreferrer"
        >
          {AmazonOrderId}
        </a>
      );
    },
  },
  {
    title: "Fecha",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Img",
    dataIndex: "productImg",
    key: "productImg",
  },
  {
    title: "Estado",
    dataIndex: "OrderStatus",
    key: "OrderStatus",
  },
  {
    title: "Total",
    dataIndex: "orderTotal",
    key: "orderTotal",
  },
];

export const OrderPage = () => {
  const [orders, setOrders] = React.useState([]);
  const [numberOfOrders, setNumberOfOrders] = React.useState(0);
  const [totalSells, setTotalSells] = useState(0);
  const [allOrdersHavePrice, setAllOrdersHavePrice] = useState(true);
  const { allProducts } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    defaultValues: {
      fromDate: dayjs(),
      toDate: dayjs(),
    },
  });

  const handleApplyFilters = () => {
    const values = form.getValues();
    setNumberOfOrders("??");
    setTotalSells("??");
    setIsLoading(true);
    SpApiService.getOrders({
      fromDate: values.fromDate.startOf("day").toISOString(),
      toDate: values.toDate.endOf("day").subtract(2, "hours").toISOString(),
      marketplaceIds: MARKETPLACES_IDS.map((el) => el.marketplaceId).join(","),
    })
      .then((res) => {
        setIsLoading(false);
        let totalSales = 0;
        const aux = res.reverse().map((el: any) => {
          let totalFromDG = 0;
          if (el.OrderStatus.toLowerCase() !== "canceled") {
            const sales: any = MARKETPLACES_IDS.find(
              (item) => item.fullUrlDomain === el.SalesChannel.toLowerCase()
            );
            if (!el.OrderTotal?.Amount) {
              setAllOrdersHavePrice(false);

              for (let index = 0; index < el.OrderItems.length; index++) {
                const { ASIN } = el.OrderItems[index];
                totalFromDG += allProducts[ASIN][sales.key].list_price;
              }
              totalSales += totalFromDG;
            } else {
              totalSales += parseFloat(el.OrderTotal.Amount);
            }
          }
          const orderTotal = el?.OrderTotal?.Amount ? (
            `${el.OrderTotal.Amount} ${el.OrderTotal.CurrencyCode}`
          ) : (
            <span style={{ color: "crimson" }}>{totalFromDG}</span>
          );

          const sales: any = MARKETPLACES_IDS.find(
            (item) => item.fullUrlDomain === el.SalesChannel.toLowerCase()
          );

          return {
            ...el,
            key: el.AmazonOrderId,
            createdAt: el.PurchaseDate,
            orderTotal: orderTotal,
            country: <Flag lang={sales.key} />,
            productImg: el.OrderItems.map((el2) => {
              return (
                <>
                  <Link to={`/products/${el2.ASIN}`}>
                    <img
                      style={{ marginRight: 4 }}
                      height={50}
                      width={50}
                      src={allProducts[el2.ASIN].spanish.images.MAIN}
                      alt={el.ASIN}
                    />
                  </Link>
                </>
              );
            }),
          };
        });
        setNumberOfOrders(aux.length);
        setTotalSells(totalSales);
        setOrders(aux);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        NotificationService.error("Error al obtener pedidos", "");
      });
  };
  return (
    <div className="order-page">
      <div className="header">
        <h2>Pedidos</h2>
      </div>
      <div className="content">
        <div className="dg-item" style={{ display: "flex" }}>
          <div>
            <Controller
              name="fromDate"
              control={form.control}
              render={({ field }) => (
                <DatePicker {...field} format="DD/MM/YYYY" />
              )}
            />
          </div>
          <div>
            <Controller
              name="toDate"
              control={form.control}
              render={({ field }) => (
                <DatePicker {...field} format="DD/MM/YYYY" />
              )}
            />
          </div>
          <div>
            <Button
              style={{ marginRight: 4 }}
              type="primary"
              onClick={handleApplyFilters}
            >
              Aplicar
            </Button>

            <Button
              style={{ marginRight: 4 }}
              type="primary"
              onClick={() => {
                form.setValue("fromDate", dayjs());
                form.setValue("toDate", dayjs());
                handleApplyFilters();
              }}
            >
              Hoy
            </Button>

            <Button
              style={{ marginRight: 4 }}
              type="primary"
              onClick={() => {
                form.setValue("fromDate", dayjs().subtract(1, "day"));
                form.setValue("toDate", dayjs().subtract(1, "day"));
                handleApplyFilters();
              }}
            >
              Ayer
            </Button>

            <Button
              style={{ marginRight: 4 }}
              type="primary"
              onClick={() => {
                form.setValue("fromDate", dayjs().subtract(2, "day"));
                form.setValue("toDate", dayjs().subtract(2, "day"));
                handleApplyFilters();
              }}
            >
              Antes de ayer
            </Button>
          </div>
          <div className="d-flex-end" style={{ flexGrow: 1 }}>
            Número de pedidos: {numberOfOrders}
          </div>
        </div>
        <div
          className="dg-item"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="d-flex-center" style={{ marginRight: 172 }}>
            Botones para consulta hace un año
          </div>
          <div>
            <Button
              style={{ marginRight: 4 }}
              type=""
              onClick={() => {
                form.setValue("fromDate", dayjs().subtract(1, "year"));
                form.setValue("toDate", dayjs().subtract(1, "year"));
                handleApplyFilters();
              }}
            >
              Hoy
            </Button>

            <Button
              style={{ marginRight: 4 }}
              type=""
              onClick={() => {
                form.setValue(
                  "fromDate",
                  dayjs().subtract(1, "year").subtract(1, "day")
                );
                form.setValue(
                  "toDate",
                  dayjs().subtract(1, "year").subtract(1, "day")
                );
                handleApplyFilters();
              }}
            >
              Ayer
            </Button>

            <Button
              style={{ marginRight: 4 }}
              type=""
              onClick={() => {
                form.setValue(
                  "fromDate",
                  dayjs().subtract(1, "year").subtract(2, "day")
                );
                form.setValue(
                  "toDate",
                  dayjs().subtract(1, "year").subtract(2, "day")
                );
                handleApplyFilters();
              }}
            >
              Antes de ayer
            </Button>
          </div>
          <div className="d-flex-end" style={{ flexGrow: 1 }}>
            Total ventas:{" "}
            <span
              style={{
                color: allOrdersHavePrice ? "black" : "crimson",
              }}
            >
              {totalSells.toFixed?.(2)}
            </span>
          </div>
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <Table dataSource={orders} columns={columns} pagination={false} />
          </div>
        )}
      </div>
    </div>
  );
};

import axios from "axios";

export class UploadService {
  public static async upload(
    file: File,
    asin,
    language,
    type
  ): Promise<string> {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("fileName", file.name);
    try {
      const response = await axios.post(
        `/upload/${asin}/${language}/${type}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || "File upload failed");
    }
  }

  public static async deleteUpload(asin, language, type): Promise<string> {
    try {
      const response = await axios.delete(
        `/upload/${asin}/${language}/${type}`
      );
      return response.data;
    } catch (error) {
      console.error({ error });

      throw new Error(error.response?.data || "File upload failed");
    }
  }

  public static async generateZip(asin, language): Promise<string> {
    try {
      const response = await axios.post(`/zip`, { asin, language });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || "File upload failed");
    }
  }
}

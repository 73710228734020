import { Button, Switch } from "antd";
import React, { useState } from "react";
import { EntryOrdersList } from "../components/EntryOrdersList.tsx";
import { useLocation } from "wouter";
import { RightLayout } from "../components/RightLayout.tsx";

export const EntryOrdersPage = () => {
  const [location, setLocation] = useLocation();
  const [showCompleted, setShowCompleted] = useState(false);

  const handleNewEntryOrder = () => {
    setLocation("/new-entry-order");
  };
  return (
    <RightLayout>
      <div
        className="dg-item"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button type="primary" onClick={handleNewEntryOrder}>
          Nuevo
        </Button>
        <div>
          <span style={{ marginRight: 24 }}>Incompletos/completos</span>
          <Switch defaultChecked={false} onChange={setShowCompleted} />
        </div>
      </div>
      <div className="dg-item">
        <EntryOrdersList showCompleted={showCompleted} />
      </div>
    </RightLayout>
  );
};

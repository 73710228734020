import { Button, Input, Modal, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import { WarehouseService } from "../services/WarehouseService.ts";
import { RightLayout } from "../components/RightLayout.tsx";

const columns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Total unidades",
    dataIndex: "totalUnits",
    key: "totalUnits",
  },
  {
    title: "Total euros",
    dataIndex: "totalEuros",
    key: "totalEuros",
  },
];

export const WarehousesPage = () => {
  const [showCreateWarehouseModal, setShowCreateWarehouseModal] =
    useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [newWarehouseName, setNewWarehouseName] = useState("");

  useEffect(() => {
    refreshWarehouses();
  }, []);

  const refreshWarehouses = () => {
    WarehouseService.getAllWarehouses().then((warehouses) => {
      setWarehouses(warehouses.data);
    });
  };

  const handleCreateWarehouseOk = () => {
    WarehouseService.createWarehouse({ name: newWarehouseName.trim() })
      .then((warehouse) => {
        setNewWarehouseName("");
        refreshWarehouses();
        notification.success({
          message: "Almacen creado",
          description: warehouse.data.name,
        });
        setShowCreateWarehouseModal(false);
      })
      .catch((error) => {
        notification.error({
          message: "Error al crear almacen",
          description: error.message,
        });
        setShowCreateWarehouseModal(false);
      });
  };

  return (
    <RightLayout>
      <Modal
        title="Crear almacen"
        open={showCreateWarehouseModal}
        onOk={handleCreateWarehouseOk}
        onCancel={() => setShowCreateWarehouseModal(false)}
      >
        <Input
          value={newWarehouseName}
          placeholder="Nombre del almacen"
          onChange={(e) => setNewWarehouseName(e.target.value)}
        />
      </Modal>

      <Button type="primary" onClick={() => setShowCreateWarehouseModal(true)}>
        Crear almacen
      </Button>
      <h1>Almacenes</h1>
      <Table columns={columns} dataSource={warehouses} pagination={false} />
    </RightLayout>
  );
};

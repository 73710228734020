import React, { useEffect, useState } from "react";
import CSVUploader from "../components/CSVUploader.tsx";
import { RefundService } from "../services/RefundService.ts";
import { Input, Select, Switch, Table, notification } from "antd";
import { CiLink } from "react-icons/ci";

export const RefundsPage = () => {
  const [refunds, setRefunds] = useState<any[]>([]);
  const [filteredRefunds, setFilteredRefunds] = useState<any[]>([]);
  const [dgState, setDgState] = useState({});
  const [amazonTicketUrl, setAmazonTicketUrl] = useState({});
  const [showCompleted, setShowCompleted] = useState(false);

  const columns = [
    {
      title: "DG State",
      dataIndex: "dgState",
      key: "dgState",
      render: (newState, el) => {
        return (
          <>
            <Select
              style={{ width: 120 }}
              onChange={(value) => {
                const aux = {
                  ...dgState,
                  [el.orderId]: value,
                };
                setDgState(aux);
                RefundService.setDgState(el.orderId, value).then((res) => {
                  notification.success({
                    message: "Dg State Updated",
                    description: "Dg State Updated successfully",
                  });
                });
              }}
              value={dgState[el.orderId]}
              options={[
                { value: "NO_TRAMITED", label: "No Tramited" },
                { value: "TRAMITED", label: "Tramited" },
                { value: "WAITING", label: "Waiting" },
                { value: "FINISHED", label: "Finished" },
              ]}
            ></Select>
          </>
        );
      },
    },
    {
      title: "Amazon Ticket Url",
      dataIndex: "amazonTicketUrl",
      key: "amazonTicketUrl",
      render: (_, el) => {
        return (
          <div className="d-flex">
            <Input
              style={{ width: 200 }}
              onChange={(e) => {
                const aux = {
                  ...amazonTicketUrl,
                  [el.orderId]: e.target.value,
                };
                setAmazonTicketUrl(aux);
                RefundService.setAmazonTicketUrl(
                  el.orderId,
                  e.target.value
                ).then((res) => {
                  notification.success({
                    message: "Amazon Ticket Url Updated",
                    description: "Amazon Ticket Url Updated successfully",
                  });
                });
              }}
              value={amazonTicketUrl[el.orderId]}
            />
            {amazonTicketUrl[el.orderId] !== "" &&
              amazonTicketUrl[el.orderId] !== undefined && (
                <CiLink
                  style={{ color: "#0087ff", fontSize: 24, cursor: "pointer" }}
                  onClick={() => {
                    window.open(amazonTicketUrl[el.orderId]);
                  }}
                />
              )}
          </div>
        );
      },
    },
    {
      title: "Refund Date",
      dataIndex: "refundDate",
      key: "refundDate",
      render: (date) => date.substring(0, 10),
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (date) => date.substring(0, 10),
    },
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Units Sold",
      dataIndex: "unitsSold",
      key: "unitsSold",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Units To Return",
      dataIndex: "unitsToReturn",
      key: "unitsToReturn",
    },
    {
      title: "Refunded To Buyer",
      dataIndex: "refundedToBuyer",
      key: "refundedToBuyer",
    },
    {
      title: "Units Received",
      dataIndex: "unitsReceived",
      key: "unitsReceived",
      width: 20,
    },
    {
      title: "Days Since Claim",
      dataIndex: "daysSinceClaim",
      key: "daysSinceClaim",
    },
    {
      title: "Reimbursed Cash",
      dataIndex: "reimbursedCash",
      key: "reimbursedCash",
    },
    {
      title: "Reimbursed Inventory",
      dataIndex: "reimbursedInventory",
      key: "reimbursedInventory",
    },
    {
      title: "Estimated Amazon Debt Sum",
      dataIndex: "estimatedAmazonDebtSum",
      key: "estimatedAmazonDebtSum",
    },
    {
      title: "Seller Board Status",
      dataIndex: "sellerBoardStatus",
      key: "sellerBoardStatus",
    },
  ];

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    RefundService.getRefunds().then((res) => {
      const newDgState = res.data.reduce((acc, el) => {
        acc[el.orderId] = el.dgState;
        return acc;
      }, {});
      setDgState(newDgState);

      const newAmazonTicketUrl = res.data.reduce((acc, el) => {
        acc[el.orderId] = el.amazonTicketUrl;
        return acc;
      }, {});
      setAmazonTicketUrl(newAmazonTicketUrl);

      const aux = res.data.map((el) => {
        return {
          ...el,
          key: el.orderId,
          dgState: el.dgState ? el.dgState : "NO_TRAMITED",
          amazonTicketUrl: el.amazonTicketUrl
            ? el.amazonTicketUrl
            : "No Ticket Url",
        };
      });
      setRefunds(aux);
    });
  };

  useEffect(() => {
    if (showCompleted) {
      setFilteredRefunds(refunds.filter((el) => el.dgState === "FINISHED"));
    } else {
      setFilteredRefunds(refunds.filter((el) => el.dgState !== "FINISHED"));
    }
  }, [showCompleted, refunds]);

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setFilteredRefunds(refunds.filter((el) => el.dgState !== "FINISHED"));
      return;
    }
    setFilteredRefunds(refunds.filter((el) => el.orderId === e.target.value));
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Reembolsos</h1>
        <div>
          <span style={{ marginRight: 24 }}>Incompletos/completos</span>
          <Switch defaultChecked={false} onChange={setShowCompleted} />
        </div>
      </div>
      <div className="dg-item">
        <CSVUploader />
      </div>

      <div>
        <Input
          className="dg-item"
          placeholder="Buscar por Orden Id"
          onChange={handleSearch}
        />
        <Table
          dataSource={filteredRefunds}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
};

import React from "react";
import { Button, Collapse, Input, notification, Switch } from "antd";
import { Controller, useForm } from "react-hook-form";
import { MARKETPLACES_IDS } from "../config.ts";
import { SpApiService } from "../services/SpApiService.ts";
import Editor from "@monaco-editor/react";

const patchTemplate = {
  productType: "AIR_FRYER",
  patches: [
    {
      op: "replace",
      path: "/attributes/color",
      value: [
        {
          value: "gris",
        },
      ],
    },
  ],
};

export const Athostests = () => {
  const [validationPreview, setValidationPreview] =
    React.useState<boolean>(true);
  const [listingData, setListingData] = React.useState<any>(null);
  const [editorValue, setEditorValue] = React.useState<any>(
    JSON.parse(JSON.stringify(patchTemplate))
  );
  const form = useForm({
    defaultValues: {
      asin: "B0D8FYM4P7",
      marketplaceId: "A1PA6795UKMFR9",
    },
  });
  const { getValues } = form;

  const handleGetClick = () => {
    const values = getValues();

    SpApiService.getListingInfo(values).then((res) => {
      setListingData(res.data);
    });
  };

  const handlePatchClick = () => {
    const values = getValues();
    const data = {
      ...values,
      body: editorValue,
      isValidationMode: validationPreview,
    };

    SpApiService.patchListing(data)
      .then((res) => {
        notification.warning({
          style: {
            width: "70vw",
            overflow: "scroll",
          },
          message: "Patch enviado",
          description: <pre>{JSON.stringify(res.data, null, 2)}</pre>,
          duration: 0,
          placement: "top",
        });
      })
      .catch((err) => {
        notification.warning({
          style: {
            width: "70vw",
            overflow: "scroll",
          },
          message: "Patch enviado",
          description: (
            <pre>{JSON.stringify(err.response.data.error, null, 2)}</pre>
          ),
          duration: 0,
          placement: "top",
        });
      });
  };

  return (
    <div>
      <div className="dg-item">
        <Collapse
          defaultActiveKey={["1"]}
          items={[
            {
              label: "Get",
              children: (
                <div>
                  <Controller
                    name="asin"
                    control={form.control}
                    render={({ field }) => (
                      <div className="dg-item">
                        <div className="dg-label">ASIN</div>
                        <div className="dg-content">
                          <Input
                            {...field}
                            className="dg-input"
                            placeholder="ASIN"
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Controller
                    name="marketplaceId"
                    control={form.control}
                    render={({ field }) => (
                      <div className="dg-item">
                        <div className="dg-label">MarketplaceId</div>
                        <div className="dg-content">
                          <Input
                            {...field}
                            className="dg-input"
                            placeholder="MarketplaceId"
                          />
                        </div>
                      </div>
                    )}
                  />
                  {MARKETPLACES_IDS.map((marketplace) => (
                    <div className="dg-item" key={marketplace.marketplaceId}>
                      <div
                        style={{
                          color:
                            marketplace.marketplaceId ===
                            form.watch("marketplaceId")
                              ? "red"
                              : "",
                        }}
                        className="dg-label"
                        onClick={() => {
                          form.setValue(
                            "marketplaceId",
                            marketplace.marketplaceId
                          );
                        }}
                      >
                        {marketplace.countryLabel}
                      </div>
                    </div>
                  ))}
                  <div className="dg-item">
                    <Button
                      className="mr-12"
                      type="primary"
                      onClick={handleGetClick}
                    >
                      Enviar
                    </Button>
                    {listingData && (
                      <Button
                        type="primary"
                        onClick={() => {
                          setListingData(null);
                        }}
                      >
                        Limpiar
                      </Button>
                    )}
                  </div>
                  {listingData && (
                    <Editor
                      options={{
                        readOnly: true,
                        minimap: {
                          enabled: false,
                        },
                      }}
                      theme="vs-dark"
                      height="90vh"
                      defaultLanguage="javascript"
                      defaultValue={JSON.stringify(listingData, null, 2)}
                    />
                  )}
                </div>
              ),
              key: "get",
            },
          ]}
        />
      </div>
      <div className="dg-item">
        <Collapse
          items={[
            {
              key: "patch",
              label: "Patch",
              children: (
                <div>
                  <Controller
                    name="asin"
                    control={form.control}
                    render={({ field }) => (
                      <div className="dg-item">
                        <div className="dg-label">ASIN</div>
                        <div className="dg-content">
                          <Input
                            {...field}
                            className="dg-input"
                            placeholder="ASIN"
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Controller
                    name="marketplaceId"
                    control={form.control}
                    render={({ field }) => (
                      <div className="dg-item">
                        <div className="dg-label">MarketplaceId</div>
                        <div className="dg-content">
                          <Input
                            {...field}
                            className="dg-input"
                            placeholder="MarketplaceId"
                          />
                        </div>
                      </div>
                    )}
                  />
                  {MARKETPLACES_IDS.map((marketplace) => (
                    <div className="dg-item" key={marketplace.marketplaceId}>
                      <div
                        style={{
                          color:
                            marketplace.marketplaceId ===
                            form.watch("marketplaceId")
                              ? "red"
                              : "",
                        }}
                        className="dg-label"
                        onClick={() => {
                          form.setValue(
                            "marketplaceId",
                            marketplace.marketplaceId
                          );
                        }}
                      >
                        {marketplace.countryLabel}
                      </div>
                    </div>
                  ))}
                  <div className="dg-item">
                    <div className="dg-item">
                      Mode
                      <Switch
                        checked={validationPreview}
                        onChange={setValidationPreview}
                      />
                      {validationPreview ? "VALIDATION_PREVIEW" : "UPDATE"}
                    </div>
                    <Button
                      className="mr-12"
                      type="primary"
                      onClick={handlePatchClick}
                      style={{
                        backgroundColor: "crimson",
                      }}
                    >
                      Patch
                    </Button>
                  </div>
                  <Editor
                    options={{
                      minimap: {
                        enabled: false,
                      },
                    }}
                    theme="vs-dark"
                    height="60vh"
                    defaultLanguage="json"
                    defaultValue={JSON.stringify(editorValue, null, 2)}
                    onChange={(data) => setEditorValue(JSON.parse(data))}
                  />
                </div>
              ),
            },
          ]}
          defaultActiveKey={["patch"]}
        />
      </div>
    </div>
  );
};

import React from "react";
import { UseFormReturn } from "react-hook-form";

export const ErrorMessage = ({
  form,
  name,
}: {
  form: UseFormReturn<any>;
  name: string;
}) => {
  return (
    <div>
      {form.formState?.errors[name] && (
        <p style={{ color: "crimson" }}>
          {form.formState.errors[name].message}
        </p>
      )}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MARKETPLACES_IDS } from "../config.ts";
import { Button, Input } from "antd";
import { SpApiService } from "../services/SpApiService.ts";

export const ProductTypesSearch = ({ selectedLanguage }) => {
  const [productTypes, setProductTypes] = useState([]);

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      word: "",
      lang: "",
      marketPlaceId: "",
    },
  });
  const {
    formState: { isDirty, isValid },
  } = form;

  useEffect(() => {
    const country: any = MARKETPLACES_IDS.find(
      (country) => country.key === selectedLanguage
    );

    if (country) {
      form.reset({
        word: "",
        lang: country.lang,
        marketPlaceId: country.marketplaceId,
      });
    }
  }, [selectedLanguage]);

  const handleSearch = () => {
    SpApiService.getProductTypesOfWordOfMarketplace({
      word: form.getValues().word,
      lang: form.getValues().lang,
      marketplaceId: form.getValues().marketPlaceId,
    }).then((res) => {
      setProductTypes(res.data.productTypes);
    });
  };

  const handleGetTypeDefinitions = (productType: any) => {
    SpApiService.getTypeDefinitionsOfProductType({
      productType,
      marketplaceId: form.getValues().marketPlaceId,
    }).then((res) => {
      console.log(res.data);
      window.open(res.data.schema.link.resource, "_blank");
    });
  };

  const handleClear = () => {
    form.reset({
      word: "",
    });
    setProductTypes([]);
  };

  return (
    <div style={{ border: "1px solid #333", padding: "10px" }}>
      <p>Buscador de productTypes</p>
      <Controller
        name="word"
        control={form.control}
        render={({ field }) => (
          <Input
            type="text"
            placeholder="word"
            {...field}
            style={{ width: "200px" }}
          />
        )}
      />
      <Button className="dg-item" type="primary" onClick={handleSearch}>
        Buscar
      </Button>
      <Button className="dg-item" type="primary" onClick={handleClear}>
        Limpiar
      </Button>
      {productTypes.length > 0 && (
        <div>
          {productTypes.map((productType: any) => (
            <div>
              <span>{productType.name}</span>
              {" - "}
              <span>{productType.displayName}</span>
              <Button
                onClick={() => handleGetTypeDefinitions(productType.name)}
                type="primary"
              >
                Ver definiciones
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

import axios from "axios";

export class OrderService {
  createOrder(data: any) {
    return axios.post("/orders", data);
  }

  getOrders() {
    return axios.get("/orders");
  }

  getOrder(id: string) {
    return axios.get(`/orders/${id}`);
  }

  updateOrder(id, data: any) {
    return axios.put(`/orders/${id}`, data);
  }

  updateOrderConfirm(id, orderAsinId, quantity, warehouseId) {
    return axios.put(`/orders/${id}/confirm/${orderAsinId}`, {
      quantity,
      warehouseId,
    });
  }
  deleteOrder(id: string) {
    return axios.delete(`/orders/${id}`);
  }

  completeOrder(id: string) {
    return axios.put(`/orders/${id}/complete`);
  }

  calculateUnitsSinceDate({ asin, date }: { asin: string; date: string }) {
    return axios.post(`/orders/calculate-units-since-date/${asin}`, { date });
  }
}

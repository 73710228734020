export const productFormDefaultValues = {
  asin: "",
  packageHeight: "",
  packageDepth: "",
  packageWidth: "",
  otherMeasures: "",
  depth: "",
  height: "",
  width: "",
  size: "",
  brand: "",
  modelName: "",
  list_price: "",
  minQuantity: "",
  internalNotes: "",
  warehouseStock: [],
  fnsku: "",
  hscode: "",
  youtubeLink: "",
  canvaLink: "",
  aplusLink: "",

  sku: "",
  weight: "",
  price: "",
  averagePrice: 0,
  allBuyPrices: [],
  stock: "",
  isBaseProduct: true,
  composedBy: [],
  spanish: {
    title: "",
    description: "",
    bullets: "",
    images: [],
  },
  german: {
    title: "",
    description: "",
    bullets: "",
    images: [],
  },
  french: {
    title: "",
    description: "",
    bullets: "",
    images: [],
  },
  italian: {
    title: "",
    description: "",
    bullets: "",
    images: [],
  },
};

import axios from "axios";

export class NoteService {
  static async getNotes() {
    return await axios.get("/notes");
  }
  static async getNote(id: string) {
    return await axios.get(`/notes/${id}`);
  }
  static async createNote(title: string, content: string) {
    return await axios.post("/notes", { title, content });
  }
  static async updateNote(id: string, title: string, content: string) {
    return await axios.put(`/notes/${id}`, { title, content });
  }
  static async deleteNote(id: string) {
    return await axios.delete(`/notes/${id}`);
  }
}

import React, { useState } from "react";
import { RightLayout } from "../components/RightLayout.tsx";
import { ProductListModalSelectable } from "../components/ProductListModalSelectable.tsx";
import { Button, InputNumber, notification } from "antd";
import { ProductTableFromAsinArray } from "../components/ProductTableFromAsinArray.tsx";
import { WarningParagraph } from "../components/WarningParagraph.tsx";
import { WarehouseSelect } from "../components/WarehouseSelect.tsx";
import { AsinService } from "../services/AsinService.ts";

const asinService = new AsinService();

export const CreatePack = () => {
  const [showProductListSelector, setShowProductListSelector] = useState(false);
  const [asins, setAsins] = useState<any>([]);
  const [warehouseSelected, setWarehouseSelected] = useState<any>("");
  const [unitsToCreate, setUnitsToCreate] = useState<any>("");
  const [baseProducts, setBaseProducts] = useState<any>([]);
  const [selectedComposedProduct, setSelectedComposedProduct] = useState<any>();

  const handleAddProduct = (data) => {
    setAsins([...asins, ...data]);
    setShowProductListSelector(false);
    asinService.getAsin(data[0]).then((res) => {
      setSelectedComposedProduct(res.data);
    });
  };

  const handleConfirm = () => {
    asinService
      .createPack(asins[0], warehouseSelected, unitsToCreate)
      .then((res) => {
        notification.success({
          message: "Pack creado",
          description: res.data.msg,
        });
        handleResetForm();
      });
  };

  const handleWarehouseSelected = (value) => {
    setWarehouseSelected(value);

    asinService.getBaseProductsOfAsin(asins[0]).then((res) => {
      setBaseProducts(res.map((product) => product.data));
    });
  };

  const handleUnitsChange = (value) => {
    setUnitsToCreate(value);
  };

  const handleCheckUnitsToCreate = async () => {
    const baseProducts = selectedComposedProduct.composedBy;
    const totalProducts = {};
    let allUnitsNeeded = true;

    for (let i = 0; i < baseProducts.length; i++) {
      const element = baseProducts[i];
      if (totalProducts[element]) {
        totalProducts[element] =
          parseInt(totalProducts[element]) + parseInt(unitsToCreate);
      } else {
        totalProducts[element] = parseInt(unitsToCreate);
      }
    }

    for (const asin in totalProducts) {
      try {
        await asinService.hasEnaughUnits(
          asin,
          totalProducts[asin],
          warehouseSelected
        );
      } catch (error) {
        allUnitsNeeded = false;
      }
    }

    if (allUnitsNeeded) {
      notification.success({
        message: "Todas las unidades necesarias",
        description: "Todas las unidades necesarias",
      });
    } else {
      notification.error({
        message: "No todos los productos tienen suficientes unidades",
        description: "No todos los productos tienen suficientes unidades",
      });
    }
  };

  const handleResetForm = () => {
    setUnitsToCreate("");
    setBaseProducts([]);
    setSelectedComposedProduct(undefined);
    setWarehouseSelected("");
    setAsins([]);
  };

  return (
    <RightLayout>
      {showProductListSelector && (
        <ProductListModalSelectable
          showOnlyBaseProducts={false}
          showOnlyComposedProducts={true}
          handleOk={handleAddProduct}
          handleCancel={() => {
            setShowProductListSelector(false);
          }}
        />
      )}
      <div className="dg-item">Crear pack</div>
      <WarningParagraph text="Hazlos de uno en uno, no selecciones más de uno" />
      <Button
        className="dg-item"
        onClick={() => setShowProductListSelector(true)}
      >
        Seleccionar
      </Button>
      <div className="dg-item">
        <ProductTableFromAsinArray asins={asins} />
      </div>

      <div className="dg-item">
        <div className="dg-item">Almacen con el que trabajar</div>
        <WarehouseSelect
          showNAOption={false}
          style={{ width: 200 }}
          onChange={handleWarehouseSelected}
          value={warehouseSelected}
        />
      </div>

      <div className="dg-item">
        <div className="dg-item">Unidades a crear</div>
        <InputNumber
          style={{ width: 200 }}
          onChange={handleUnitsChange}
          value={unitsToCreate}
        />
        <Button className="dg-item" onClick={handleCheckUnitsToCreate}>
          Comprobar
        </Button>
      </div>
      {baseProducts.length > 0 && (
        <div className="dg-item">
          <div className="dg-item">Bases</div>

          <ProductTableFromAsinArray
            asins={baseProducts.map((product) => product.asin)}
          />
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          type="primary"
          className="dg-item"
          onClick={handleConfirm}
          disabled={
            asins.length === 0 ||
            asins.length > 1 ||
            warehouseSelected === "" ||
            unitsToCreate === ""
          }
        >
          Confirmar
        </Button>

        <Button
          className="dg-item"
          disabled={asins.length === 0}
          onClick={handleResetForm}
        >
          Resetear
        </Button>
      </div>
    </RightLayout>
  );
};

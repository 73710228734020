import React from "react";
import { Tooltip } from "antd";

export const TrimWithTooltip = ({ text }) => {
  const maxLength = 70;

  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  const trimmedText = text.substring(0, maxLength) + "...";

  return (
    <Tooltip title={text}>
      <span>{trimmedText}</span>
    </Tooltip>
  );
};

import { Button, Modal } from "antd";
import React from "react";
import { ChatService } from "../../services/ChatService.ts";

const confirmReset = ({
  field,
  currentValue,
  newValue,
  form,
  index,
  total,
  selectedLanguage,
  isTranslateOption,
  chatGptPrompts,
  propertyType,
}) => {
  return new Promise((resolve) => {
    let modal;
    const translateAndFill = () => {
      let result = "";
      if (chatGptPrompts["translate-hidden-component"]) {
        const lastItem =
          chatGptPrompts["translate-hidden-component"].prompt.length - 1;
        let prePrompt = "";
        if (chatGptPrompts["translate-hidden-component"].prompt?.length > 0) {
          prePrompt =
            chatGptPrompts["translate-hidden-component"].prompt[lastItem].text;
        }
        let finalPrompt = prePrompt.replace(
          "{{targetLanguage}}",
          selectedLanguage
        );
        result = finalPrompt.replace("{{selectedText}}", currentValue);
      }
      ChatService.askToChat({
        message: result,
        force: false,
      }).then((res) => {
        form.setValue(field, res.data.response);
        modal.destroy();
        resolve(true);
      });
    };
    modal = Modal.confirm({
      title: (
        <div
          style={{
            color: propertyType === "language" ? "green" : "crimson",
          }}
        >
          ({propertyType === "general" ? "General" : "Idioma"})
          {selectedLanguage} - El campo <b>{field}</b> ya tiene un valor (
          {index + 1}/{total}){" "}
        </div>
      ),
      content: (
        <div>
          <p>El valor actual es: {currentValue}</p>
          <p style={{ color: "blueviolet" }}>El valor en AWS es: {newValue}</p>
          <p>¿Quieres sobreescribirlo?</p>
        </div>
      ),
      width: "70vw",
      footer: (
        <div className="d-flex justify-content-end">
          <div style={{ flex: 1 }}></div>
          {isTranslateOption && (
            <Button className="mr-12" type="dashed" onClick={translateAndFill}>
              Traducir y aplicar
            </Button>
          )}

          <Button
            className="mr-12"
            type="primary"
            onClick={() => {
              form.setValue(field, newValue);
              modal.destroy();
              resolve(true);
            }}
          >
            Sobreescribir
          </Button>
          <Button
            onClick={() => {
              modal.destroy();
              resolve(true);
            }}
          >
            Cancelar
          </Button>
        </div>
      ),
    });
  });
};

const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const setFormValueIfExists = async ({
  form,
  field,
  value,
  index,
  total,
  selectedLanguage,
  isTranslateOption,
  chatGptPrompts,
  propertyType,
}) => {
  const formValues = form.getValues();
  const currentValue = getNestedValue(formValues, field);

  if (selectedLanguage !== "spanish") {
    if (propertyType === "general") {
      return;
    }
  }

  if (value == currentValue) {
    return;
  }

  await confirmReset({
    field,
    currentValue,
    newValue: value,
    form,
    index,
    total,
    selectedLanguage,
    isTranslateOption,
    chatGptPrompts,
    propertyType,
  });
};

export const refillForm = async ({
  form,
  data,
  selectedLanguage,
  chatGptPrompts,
}) => {
  const fields = [
    {
      field: "weight",
      value: data.attributes?.item_package_weight?.[0]?.value,
      isTranslateOption: false,
      propertyType: "general",
    },
    {
      field: "item_dimensions_length",
      value: data.attributes?.item_dimensions?.[0]?.length?.value,
      isTranslateOption: false,
      propertyType: "general",
    },
    {
      field: "item_dimensions_height",
      value: data.attributes?.item_dimensions?.[0]?.height?.value,
      isTranslateOption: false,
      propertyType: "general",
    },
    {
      field: "item_dimensions_width",
      value: data.attributes?.item_dimensions?.[0]?.width?.value,
      isTranslateOption: false,
      propertyType: "general",
    },
    {
      field: "item_package_dimensions_length",
      value: data.attributes?.item_package_dimensions?.[0]?.length?.value,
      isTranslateOption: false,
      propertyType: "general",
    },
    {
      field: "item_package_dimensions_height",
      value: data.attributes?.item_package_dimensions?.[0]?.height?.value,
      isTranslateOption: false,
      propertyType: "general",
    },
    {
      field: "item_package_dimensions_width",
      value: data.attributes?.item_package_dimensions?.[0]?.width?.value,
      isTranslateOption: false,
      propertyType: "general",
    },
    {
      field: "size",
      value: data.attributes?.size?.[0]?.value,
      isTranslateOption: true,
      propertyType: "general",
    },
    {
      field: "modelName",
      value: data.attributes?.model_name?.[0]?.value,
      isTranslateOption: false,
      propertyType: "general",
    },
    {
      field: "brand",
      value: data.attributes?.brand?.[0]?.value,
      isTranslateOption: false,
      propertyType: "general",
    },
    {
      field: "fnsku",
      value: data.summaries?.[0]?.fnSku,
      isTranslateOption: false,
      propertyType: "general",
    },
    {
      field: `${selectedLanguage}.title`,
      value: data.attributes?.item_name?.[0]?.value,
      isTranslateOption: true,
      propertyType: "language",
    },
    {
      field: `${selectedLanguage}.description`,
      value: data.attributes?.product_description?.[0]?.value,
      isTranslateOption: true,
      propertyType: "language",
    },
    {
      field: `${selectedLanguage}.bullets`,
      value: data.attributes?.bullet_point?.map((el) => el.value).join("\n\n"),
      isTranslateOption: true,
      propertyType: "language",
    },
    {
      field: `${selectedLanguage}.keywords`,
      value: data.attributes?.generic_keyword?.[0]?.value,
      isTranslateOption: true,
      propertyType: "language",
    },
    {
      field: `${selectedLanguage}.variantName`,
      value: data.attributes?.color?.[0]?.value,
      isTranslateOption: true,
      propertyType: "language",
    },
    {
      field: `${selectedLanguage}.recommended_browse_nodes`,
      value: data.attributes?.recommended_browse_nodes?.[0]?.value,
      isTranslateOption: false,
      propertyType: "language",
    },
    {
      field: `${selectedLanguage}.list_price`,
      value:
        data.attributes?.purchasable_offer?.[0]?.our_price?.[0]?.schedule?.[0]
          ?.value_with_tax,
      isTranslateOption: false,
      propertyType: "language",
    },
    {
      field: `${selectedLanguage}.material`,
      value: data.attributes?.material?.[0]?.value,
      isTranslateOption: true,
      propertyType: "language",
    },

    {
      field: `${selectedLanguage}.productType`,
      value: data.summaries?.[0]?.productType,
      isTranslateOption: false,
      propertyType: "language",
    },
    {
      field: `${selectedLanguage}.item_shape`,
      value: data.attributes?.[0]?.item_shape,
      isTranslateOption: false,
      propertyType: "language",
    },
    {
      field: `${selectedLanguage}.special_feature`,
      value: data.attributes?.[0]?.special_feature,
      isTranslateOption: false,
      propertyType: "language",
    },
  ];

  const total = fields.length;

  for (let index = 0; index < total; index++) {
    const { field, value, isTranslateOption, propertyType } = fields[index];
    await setFormValueIfExists({
      form,
      field,
      value,
      index,
      total,
      selectedLanguage,
      isTranslateOption,
      chatGptPrompts,
      propertyType,
    });
  }
};

import axios from "axios";

export class XlsService {
  static generateXls = async ({ asin, selectedLanguage }) => {
    return axios.get(`/xls/update/${asin}/${selectedLanguage}`);
  };

  static generateParentProductXls = async ({ asin, selectedLanguage }: any) => {
    return axios.get(`/xls/parent/${asin}/${selectedLanguage}`);
  };
}

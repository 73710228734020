import React, { useState } from "react";
import { AsinService } from "../services/AsinService.ts";
import { Button, Input, Table, notification } from "antd";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "./ErrorMessage.tsx";
import { DeliveryToAwsService } from "../services/DeliveryToAwsService.ts";
import { Link } from "wouter";
import { StockSummary } from "./StockSummary.tsx";
import { WarehouseService } from "../services/WarehouseService.ts";

interface Delivery {
  _id: string;
  title: string;
  asins: [
    {
      asin: string;
      quantity: number;
      _id: string;
    }
  ];
  createdAt: string;
  updatedAt: string;
}

interface DeliveryFormProps {
  delivery: Delivery;
}

const asinService = new AsinService();

export const DeliveryForm = ({ delivery }: DeliveryFormProps) => {
  const columns = [
    {
      title: "Img",
      dataIndex: "image",
      key: "image",
    },
    {
      title: "Asin",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "Titulo",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      render: (stock: any, el: any) => {
        return <StockSummary productId={el.asin} />;
      },
    },
    {
      title: "Unidades a enviar",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Unidades enviadas",
      dataIndex: "finalQuantity",
      key: "finalQuantity",
    },
  ];
  const [allAsins, setAllAsins] = useState([]);

  const form = useForm({
    defaultValues: delivery.asins.reduce((acc, el) => {
      acc[el.asin] = el.finalQuantity;
      return acc;
    }, {}),
    mode: "all",
  });
  React.useEffect(() => {
    refresh();
  }, [delivery]);

  const refresh = () => {
    const promises = delivery.asins.map((asin) =>
      asinService.getAsin(asin.asin)
    );
    Promise.all(promises).then((res) => {
      let aux: any = [];
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        aux = [...aux, element.data];
      }

      setAllAsins(aux);
    });
  };

  const handleConfirm = () => {
    const values = form.getValues();

    DeliveryToAwsService.updateDeliveryToAws(delivery._id, {
      title: delivery.title,
      asins: delivery.asins.map((asin) => ({
        asin: asin.asin,
        quantity: asin.quantity,
        finalQuantity: parseInt(values[asin.asin]),
      })),
    }).then((res) => {
      notification.success({
        message: "Delivery to aws updated",
        description: "Delivery to aws updated",
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  };

  return (
    <div>
      {!delivery.isProcessed ? (
        <Button
          disabled={!form.formState.isValid}
          className="dg-item"
          type="primary"
          onClick={handleConfirm}
        >
          Confirmar
        </Button>
      ) : (
        <Link to={`/weight-calculator/${delivery._id}`}>
          Calculador de pesos
        </Link>
      )}
      <Table
        pagination={false}
        dataSource={allAsins.map((product) => {
          return {
            key: product._id,
            asin: product.asin,
            image: (
              <img
                height={50}
                width={50}
                src={product.spanish?.images?.MAIN || "./images/no-image.png"}
                alt={product.title}
              />
            ),
            title: product.spanish?.title || "Sin titulo",
            stock: product.stock || 0,
            quantity:
              delivery.asins.find((el) => el.asin === product.asin)?.quantity ||
              0,
            finalQuantity: (
              <Controller
                name={`${product.asin}`}
                control={form.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Input
                      value={field.value}
                      disabled={delivery.isProcessed}
                      {...field}
                      placeholder="Cantidad enviada al final"
                    />
                    <ErrorMessage form={form} name={`${product.asin}`} />
                  </>
                )}
              />
            ),
          };
        })}
        columns={columns}
      />

      <div>Created at: {delivery.createdAt}</div>
      <div>Updated at: {delivery.updatedAt}</div>
    </div>
  );
};

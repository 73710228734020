import React, { useContext, useEffect } from "react";
import { DataContext } from "../context/DataContext.tsx";
import { Button, Card, Collapse, Input, Tooltip } from "antd";
import { Controller, useForm } from "react-hook-form";
import { ChatGptPromptService } from "../services/ChatGptPromptService.ts";
import { IoInformationCircleOutline } from "react-icons/io5";
import dayjs from "dayjs";

export const ChatGptProptsForm = () => {
  const { chatGptPrompts, refreshData } = useContext(DataContext);

  const form = useForm({
    defaultValues: {
      _id: "",
      prompt: [],
      notes: "",
      interpolations: "",
    },
  });
  const {
    formState: { isDirty, isValid },
  } = form;

  const otherForm = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    const aux = Object.keys(chatGptPrompts).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {});

    otherForm.reset(aux);
  }, [chatGptPrompts, otherForm]);

  const handleAddPromptKey = () => {
    const values = form.getValues();
    ChatGptPromptService.createChatGptPrompt(values).then((res) => {
      form.reset();
      refreshData();
    });
  };

  return (
    <div>
      <Collapse
        className="dg-item"
        items={[
          {
            key: "1",
            label: "Añadir nueva key",
            children: (
              <div>
                <div className="dg-item">
                  <Controller
                    name="_id"
                    control={form.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="Introduce la key del prompt aquí"
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                </div>
                <div className="dg-item">
                  <Controller
                    name="notes"
                    control={form.control}
                    render={({ field }) => (
                      <Input
                        placeholder="Introduce las anotaciones aquí"
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                </div>
                <div className="dg-item">
                  <Controller
                    name="interpolations"
                    control={form.control}
                    render={({ field }) => (
                      <Input
                        placeholder="Introduce las interpolaciones aqui, {{title}}||{{description}}"
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                </div>
                <div>
                  <Button
                    type="primary"
                    disabled={!isDirty || !isValid}
                    onClick={handleAddPromptKey}
                  >
                    Add Prompt Key
                  </Button>
                </div>
              </div>
            ),
          },
        ]}
        defaultActiveKey={["1"]}
      />
      <div>
        {Object.keys(chatGptPrompts).map((key: any) => (
          <div key={key} className="dg-item">
            <Card
              title={key}
              extra={
                <Tooltip title={chatGptPrompts[key].notes}>
                  <IoInformationCircleOutline
                    color="#000022"
                    fontSize={24}
                    style={{ marginLeft: 10 }}
                  />
                </Tooltip>
              }
              style={{ width: "100%" }}
            >
              <div>
                <div className="dg-item">
                  Interpolaciones: {chatGptPrompts[key].interpolations}
                </div>
                {chatGptPrompts[key].prompt.sort((a, b) => b.date - a.date)
                  .length > 0 ? (
                  <>
                    {chatGptPrompts[key].prompt.map((prompt, index) => (
                      <div key={index} className="dg-item">
                        <b>
                          {dayjs(prompt.date).format("YYYY-MM-DD HH:mm:ss")}
                        </b>{" "}
                        - {prompt.text}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="dg-item warning">
                    No hay prompts para este key
                  </div>
                )}

                <Controller
                  name={key}
                  control={otherForm.control}
                  render={({ field }) => (
                    <Input
                      className="dg-item"
                      placeholder="Introduce un prompt aqui"
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />

                <Button
                  type="primary"
                  disabled={otherForm?.watch(key)?.length === 0}
                  onClick={() => {
                    ChatGptPromptService.addOnePrompt(key, {
                      prompt: otherForm.getValues()[key],
                    }).then((res) => {
                      otherForm.setValue(key, "");
                      refreshData();
                    });
                  }}
                >
                  Add Prompt
                </Button>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

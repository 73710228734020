import { Button, Input, Popconfirm } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "./ErrorMessage.tsx";
import { ProductListModalSelectable } from "./ProductListModalSelectable.tsx";
import { DataContext } from "../context/DataContext.tsx";
import { MdDelete } from "react-icons/md";

export const ParentProductForm = ({ form, isEditing }: any) => {
  const [showProductsTable, setShowProductsTable] = React.useState(false);
  const { allProducts } = React.useContext(DataContext);

  return (
    <>
      {showProductsTable && (
        <ProductListModalSelectable
          showOnlyBaseProducts={false}
          showOnlyComposedProducts={false}
          handleOk={(data) => {
            const values = form.getValues();
            const newChildrens: any = [...values.children, ...data];
            form.setValue("children", newChildrens);
            setShowProductsTable(false);
          }}
          handleCancel={() => {
            setShowProductsTable(false);
          }}
        />
      )}
      <div>
        <div className="dg-item">
          <Controller
            name="asin"
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <Input {...field} placeholder="Asin, mismo que en amazon" />
                <ErrorMessage form={form} name="asin" />
              </>
            )}
          />
        </div>
        <div className="dg-item">
          <Controller
            name="sku"
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  placeholder="sku, creo que se puede inventar"
                />
                <ErrorMessage form={form} name="sku" />
              </>
            )}
          />
        </div>
        <div className="dg-item">
          <Controller
            name="title"
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <Input {...field} placeholder="Titulo del producto padre" />
                <ErrorMessage form={form} name="title" />
              </>
            )}
          />
        </div>
        <div className="dg-item">
          {form.watch("children").map((child, index) => {
            return (
              <div key={index} className="d-flex-center">
                <img
                  height={100}
                  width={100}
                  src={allProducts[child].spanish.images.MAIN}
                  alt="producto"
                />
                <div>{allProducts[child].spanish.title}</div>
                <div style={{ marginLeft: "auto" }}>
                  <Popconfirm
                    title="¿Estás seguro que quieres borrar este hijo? El cambio no se guardará hasta que lo confirmes."
                    onConfirm={(e) => {
                      const newChildrens = form
                        .getValues()
                        .children.filter((el) => el !== child);
                      form.setValue("children", newChildrens);
                    }}
                  >
                    <MdDelete
                      fontSize={24}
                      style={{ cursor: "pointer", color: "crimson" }}
                      onClick={(e) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                      }}
                    />
                  </Popconfirm>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              setShowProductsTable(true);
            }}
          >
            Añadir hijo
          </Button>
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { DeliveryToAwsService } from "../services/DeliveryToAwsService.ts";
import { Button, Input, InputNumber, Steps } from "antd";
import { ErrorMessage } from "./ErrorMessage.tsx";
import BoxSummary from "./BoxSummary.tsx";

export const WeightCalculator = ({ deliveryId }) => {
  const [deliveryToAws, setDeliveryToAws] = useState({});
  const [assistant, setAssistant] = useState(false);
  const [current, setCurrent] = useState(0);
  const [steps, setSteps] = useState([]);
  const [items, setItems] = useState([]);
  const [boxes, setBoxes] = useState(0);
  const boxContent = useRef([]);
  const currentBox = useRef(0);
  const [final, setFinal] = useState();

  useEffect(() => {
    const fetchDeliveryData = async () => {
      try {
        const res = await DeliveryToAwsService.getDeliveryToAwsById(deliveryId);
        setDeliveryToAws(res.data);
      } catch (error) {
        console.error("Error fetching delivery data:", error);
      }
    };
    fetchDeliveryData();
  }, [deliveryId]);

  const next = () => {
    setCurrent((prevCurrent) => prevCurrent + 1);
    currentBox.current = current + 1;
  };

  const prev = () => {
    setCurrent((prevCurrent) => prevCurrent - 1);
    currentBox.current = current - 1;
  };

  const handleStartWizard = () => {
    const initialBoxContent = Array.from({ length: boxes }, () =>
      deliveryToAws.asins.reduce(
        (acc, el) => ({
          ...acc,
          [el.item.asin]: 0,
        }),
        {}
      )
    );
    boxContent.current = initialBoxContent;

    const stepArray = Array.from({ length: boxes }, (_, index) => ({
      title: `Caja ${index + 1}`,
      content: content,
    }));

    setSteps(stepArray);
    setItems(stepArray.map((item) => ({ key: item.title, title: item.title })));
    setAssistant(true);
  };

  const handleBoxContentChange = (event, element) => {
    const updatedValue = event.target.value;
    const updatedBoxContent = [...boxContent.current];
    updatedBoxContent[currentBox.current][element.item.asin] = updatedValue;
    boxContent.current = updatedBoxContent;
  };

  const content = (
    <div>
      {deliveryToAws.asins?.map((el) => (
        <div key={el.item.asin} className="dg-item d-flex align-center">
          <div className="mr-12">
            <img
              alt="imagen"
              height={50}
              width={50}
              src={el.product.spanish?.images?.MAIN || "./no-image.jpg"}
            />
          </div>
          <Input
            className="mr-12"
            style={{ width: 100 }}
            placeholder="Cantidad"
            onChange={(e) => handleBoxContentChange(e, el)}
          />
          <div className="mr-12">{el.item.asin}</div>
          <div className="mr-12">{el.item.finalQuantity}</div>
          <div className="mr-12">
            {el.product.spanish?.title || "Sin titulo"}
          </div>
        </div>
      ))}
    </div>
  );

  const handleDone = () => {
    console.info(boxContent.current);
  };

  return (
    <div>
      <div className="dg-item">Calculador de pesos de cajas</div>
      <div
        className="dg-item"
        style={{ width: 500, display: "flex", alignItems: "center" }}
      >
        {!assistant && <div className="mr-12">Cantidad de cajas</div>}
        <InputNumber min={1} defaultValue={0} onChange={setBoxes} />
      </div>

      <div className="dg-item">
        <Button
          disabled={boxes < 1 || !deliveryToAws.asins?.length}
          type="primary"
          onClick={handleStartWizard}
        >
          Iniciar asistente
        </Button>
      </div>
      {assistant && (
        <>
          <div className="dg-item">
            <Steps current={current} items={items} />
            <div>{steps[current]?.content}</div>
          </div>
          <div className="dg-item">
            {current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={prev}>
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={next}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={handleDone}>
                Done
              </Button>
            )}
          </div>
        </>
      )}
      <div className="dg-item">
        <div>Resumen</div>
        <Button
          type="primary"
          onClick={() => {
            setFinal(null);
            setTimeout(() => {
              setFinal(boxContent.current);
            }, 10);
          }}
        >
          Refrescar final
        </Button>
        {/* <div>{JSON.stringify(final)}</div> */}
        <div>
          {" "}
          {final && (
            <BoxSummary finalData={final} deliveryToAws={deliveryToAws} />
          )}
        </div>
      </div>
    </div>
  );
};

import axios from "axios";

export class ChatService {
  static askToChat = async ({
    message,
    force,
  }: {
    message: string;
    force: boolean;
  }) => {
    return await axios.post("/chat", { message, force });
  };
}

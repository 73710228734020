import React, { createContext, useEffect, useState } from "react";
import { WarehouseService } from "../services/WarehouseService.ts";
import { AsinService } from "../services/AsinService.ts";
import { ChatGptPromptService } from "../services/ChatGptPromptService.ts";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { NotificationService } from "../services/NotificationService.ts";

export const DataContext = createContext({
  warehouseNames: {},
  allProducts: {},
  allProductsBySku: {},
  chatGptPrompts: {},
  notifications: [],
  refreshData: () => {},
});

const asinService = new AsinService();

export const DataContextProvider = ({ children }) => {
  // const socketUrl = "ws://dg.digital-goods.es/ws";

  // const {
  //   sendMessage,
  //   sendJsonMessage,
  //   lastMessage,
  //   lastJsonMessage,
  //   readyState,
  //   getWebSocket,
  // } = useWebSocket(socketUrl, {
  //   onOpen: () => console.log("opened"),
  //   //Will attempt to reconnect on all close events, such as server shutting down
  //   shouldReconnect: (closeEvent) => true,
  // });

  // useEffect(() => {
  //   console.log("readyState", readyState);
  //   setTimeout(() => {
  //     sendJsonMessage({ message: "Hello from client" });
  //   }, 5000);
  // }, [readyState]);

  // useEffect(() => {
  //   if (lastMessage !== null) {
  //     console.log("lastMessage", lastMessage);
  //     console.log(JSON.parse(lastMessage.data));
  //   }
  // }, [lastMessage]);

  const [warehouseNames, setWarehouseNames] = React.useState<any>({
    warehouseNames: {},
  });
  const [allProducts, setAllProducts] = useState<any>([]);
  const [allProductsBySku, setAllProductsBySku] = useState<any>({});
  const [chatGptPrompts, setChatGptPrompts] = useState<any>({});

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async () => {
    Promise.all([
      WarehouseService.getAllWarehouses(),
      asinService.getAllAsins(),
      ChatGptPromptService.getChatGptPrompts(),
    ]).then((res) => {
      const auxWarehouses = res[0].data.reduce((acc, el) => {
        acc[el._id] = el.name;
        return acc;
      }, {});
      setWarehouseNames(auxWarehouses);
      const auxProducts = res[1].data.reduce((acc, el) => {
        acc[el.asin] = el;
        return acc;
      }, {});
      setAllProducts(auxProducts);
      const auxProductsBySku = res[1].data.reduce((acc, el) => {
        if (el.sku === undefined || el.sku === "") return acc;
        acc[el.sku] = el;
        return acc;
      }, {});

      setAllProductsBySku(auxProductsBySku);
      const auxChatGptPrompts = res[2].data.reduce((acc, el) => {
        acc[el._id] = el;
        return acc;
      }, {});

      setChatGptPrompts(auxChatGptPrompts);
    });
  };

  return (
    <DataContext.Provider
      value={{
        warehouseNames,
        allProducts,
        allProductsBySku,
        chatGptPrompts,
        refreshData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

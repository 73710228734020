import React, { useContext, useEffect } from "react";
import { AsinService } from "../services/AsinService.ts";
import { Tooltip } from "antd";
import { DataContext } from "../context/DataContext.tsx";

export const StockSummary = ({ productId }) => {
  const [stock, setStock] = React.useState<any>({});
  const { warehouseNames } = useContext(DataContext);

  useEffect(() => {
    const asinService = new AsinService();
    Promise.all([asinService.getAsin(productId)]).then((res) => {
      setStock({
        stock: res[0].data.stock,
        warehouseStock: res[0].data.warehouseStock,
      });
    });
  }, [productId]);

  /*
{
    "stock": {
        "stock": 84,
        "warehouseStock": [
            {
                "warehouse": "6659ec452ab6989a20482014",
                "stock": 45,
                "_id": "66682b2b23964ce675883514"
            },
            {
                "warehouse": "6659ec3f2ab6989a2048200e",
                "stock": 35,
                "_id": "665c8c6157251da63c4b6f2e"
            },
            {
                "warehouse": "666b518aebcb98506b55d9b2",
                "stock": 4,
                "_id": "666b518aebcb98506b55da1a"
            }
        ]
    },
    "warehouseNames": {
        "6659ec3f2ab6989a2048200e": "Belorado",
        "6659ec452ab6989a20482014": "Logroño",
        "666b518aebcb98506b55d9b2": "Roto"
    }
}
 */
  const tooltipText = (
    <div>
      <p>Stock total: {stock?.stock}</p>
      {stock?.warehouseStock?.map((ware, index) => {
        return (
          <p key={ware.warehouse}>
            Stock en {warehouseNames[ware.warehouse]}: {ware.stock}
          </p>
        );
      })}
    </div>
  );
  return (
    <Tooltip title={tooltipText}>
      <span>{stock.stock}</span>
    </Tooltip>
  );
};

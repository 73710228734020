import React, { useContext, useEffect, useState } from "react";
import { SpApiService } from "../services/SpApiService.ts";
import { Button, Collapse, notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DataContext } from "../context/DataContext.tsx";
import { Flag } from "../components/flags/Flag.tsx";
import { Editor } from "@monaco-editor/react";
import { Link } from "wouter";

export const CheckAllErrorsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const { allProductsBySku } = useContext(DataContext);
  const [numberOfErrors, setNumberOfErrors] = useState(0);

  useEffect(() => {
    const errorsData = localStorage.getItem("errorsData");
    if (errorsData) {
      console.log(JSON.parse(errorsData));
      setData(JSON.parse(errorsData));
    }
  }, []);

  useEffect(() => {
    const keys = Object.keys(data);
    let totalErrors = 0;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (data[key]?.issues?.length > 0) {
        totalErrors += data[key]?.issues?.length;
      }
    }

    setNumberOfErrors(totalErrors);
  }, [data]);

  const handleSearchErrorsOnAllProducts = () => {
    setIsLoading(true);
    localStorage.removeItem("errorsData");
    setData({});
    SpApiService.searchErrorsOnAllProducts()
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setIsLoading(false);
        localStorage.setItem("errorsData", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };
  if (isLoading) {
    return (
      <div className="d-flex-center dg-item">
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 16, color: "#333" }} spin />
          }
        />{" "}
        Cargando...
        <p>
          Esta operacion comprueba todos los productos en todos los
          marketplaces, asi que puede tardar un buen rato
        </p>
      </div>
    );
  }

  const handleSave = () => {
    localStorage.setItem("errorsData", JSON.stringify(data));
    notification.success({
      message: "Guardado",
      description: "Los datos se guardaron en el navegador",
    });
  };

  const handleReset = () => {
    localStorage.removeItem("errorsData");
    setData({});
    notification.success({
      message: "Limpiado",
      description: "Los datos se limpiaron",
    });
  };
  return (
    <div>
      <h2>Lista de errores. Total de errores: {numberOfErrors}</h2>
      <div className="dg-item">
        <Button className="mr-12" onClick={handleSave} type="primary">
          Guardar
        </Button>
        <Button className="mr-12" onClick={handleReset} type="primary">
          Limpiar
        </Button>
        <Button
          className="mr-12"
          onClick={handleSearchErrorsOnAllProducts}
          type="primary"
        >
          Cargar desde API
        </Button>
      </div>
      {Object.keys(data).map((key) => {
        const langKey = key.split("_")[0];
        const sku = key.split("_")[1];
        return (
          <Collapse
            className="dg-item"
            defaultActiveKey={["1"]}
            items={[
              {
                label: (
                  <div>
                    <Flag lang={langKey} />
                    <img
                      src={data[key]?.summaries?.[0]?.mainImage?.link}
                      alt="product"
                      height={50}
                      width={50}
                    />{" "}
                    {key}
                    {" - "}
                    <span
                      style={{
                        color:
                          data[key]?.issues
                            ?.map((el) => el.severity)
                            .join(", ")
                            .indexOf("ERROR") > -1
                            ? "red"
                            : "black",
                      }}
                    >
                      {data[key]?.issues?.map((el) => el.severity).join(", ")}
                    </span>
                  </div>
                ),
                children: (
                  <div key={key} style={{ overflow: "auto" }}>
                    <a
                      href={`/products/${allProductsBySku[sku]?.asin}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ir a producto {allProductsBySku[sku]?.asin}
                    </a>

                    <Editor
                      options={{
                        readOnly: true,
                        minimap: {
                          enabled: false,
                        },
                        wordWrap: "on",
                      }}
                      theme="vs-dark"
                      height="50vh"
                      defaultLanguage="javascript"
                      defaultValue={JSON.stringify(data[key]?.issues, null, 2)}
                    />
                  </div>
                ),
                key: "get",
              },
            ]}
          />
        );
      })}
    </div>
  );
};

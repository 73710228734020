import React from "react";
import "./DiffViewer.scss";

interface DiffViewerProps {
  differences: {
    path: string;
    listingValue: any;
    backendValue: any;
  }[];
}

const excludePaths = [
  "asin",
  "product_site_launch_date",
  "fulfillment_availability",
  "generic_keyword",
  "country_of_origin",
  "merchant_shipping_group",
  "merchant_suggested_asin",
  "condition_type",
  "other_product_image_locator_5",
  "other_product_image_locator_4",
  "other_product_image_locator_3",
  "other_product_image_locator_2",
  "other_product_image_locator_1",
  "main_product_image_locator",
  "parentage_level",
  "child_parent_sku_relationship",
  "adultProduct",
  "autographed",
  "itemClassification",
  "memorabilia",
  "fulfillment_availability",
  "generic_keyword",
  "country_of_origin",
  "variation_theme",
  "purchasable_offer",
];

const formatValue = (value: any) => {
  if (typeof value === "object" && value !== null) {
    return <pre>{JSON.stringify(value, null, 2)}</pre>; // Formatear como JSON con indentación
  }
  return <span>{String(value)}</span>; // Convertir a string si no es un objeto
};

export const DiffViewer: React.FC<DiffViewerProps> = ({ differences }) => {
  return (
    <div className="diff-viewer">
      {differences.length === 0 ? (
        <p>No differences found. The data is identical.</p>
      ) : (
        differences
          .filter((diff) => {
            return !excludePaths.includes(diff.path);
          }) // Filtrar diferencias excluidas
          .map((diff, index) => (
            <div key={index} className="diff-item">
              <div className="diff-path">{diff.path}</div>
              <div className="diff-values">
                <div className="diff-listing">
                  <span className="diff-label">Listing Data:</span>
                  <div className="diff-value diff-added">
                    {formatValue(diff.listingValue)}
                  </div>
                </div>
                <div className="diff-backend">
                  <span className="diff-label">Backend Data:</span>
                  <div className="diff-value diff-removed">
                    {formatValue(diff.backendValue)}
                  </div>
                </div>
              </div>
            </div>
          ))
      )}
    </div>
  );
};

import {
  Button,
  Collapse,
  Modal,
  notification,
  Popconfirm,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { ParentProductServices } from "../services/ParentProductServices.ts";
import { ParentProductForm } from "../components/ParentProductForm.tsx";
import { useForm } from "react-hook-form";
import { Link } from "wouter";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { XlsService } from "../services/XlsService.ts";

export const ParentsPage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [parentProducts, setParentProducts] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const form = useForm({
    defaultValues: { asin: "", sku: "", title: "", children: [] },
    mode: "all",
  });

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    ParentProductServices.getAllParentProducts().then((response) => {
      setParentProducts(response.data);
    });
  };

  const handleUpdate = () => {
    ParentProductServices.updateParentProduct(form.getValues()).then(
      (response) => {
        setShowModal(false);
        notification.success({
          message: "Producto actualizado",
          description: "Producto actualizado correctamente",
        });
        refresh();
        setIsEditing(false);
      }
    );
  };

  const handleCreate = () => {
    ParentProductServices.createParentProduct(form.getValues()).then(
      (response) => {
        setShowModal(false);
        notification.success({
          message: "Producto creado",
          description: "Producto creado correctamente",
        });
        refresh();
      }
    );
  };

  const handleCreateParentProductCsv = (parentProduct: any) => {
    XlsService.generateParentProductXls({
      asin: parentProduct.asin,
      selectedLanguage,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = response.headers["x-filename"]; // Obtener el nombre del archivo de los headers

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // El nombre del archivo que se descargará
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      notification.success({
        message: "Success",
        description: "The file was generated successfully",
      });
    });
  };

  function allCategoriesMatch(children) {
    if (children.length === 0) return true; // Si no hay elementos, devolvemos true por defecto
    const firstCategory = children[0].productType;
    return children.every((child) => child.productType === firstCategory);
  }

  const isGenerateBtnDisabled = (el) => {
    if (selectedLanguage === "" || selectedLanguage === undefined) return true;

    const isAllVariableNamesSetted = el.children.every((child) => {
      return (
        child[selectedLanguage]?.variantName === "" ||
        child[selectedLanguage]?.variantName === undefined
      );
    });

    const isCategoryMatch = allCategoriesMatch(el.children);

    return isAllVariableNamesSetted || !isCategoryMatch;
  };

  return (
    <div>
      {showModal && (
        <Modal
          width={1000}
          title="Crear nuevo producto padre"
          open={true}
          okText={isEditing ? "Actualizar" : "Crear"}
          onOk={(data) => {
            isEditing ? handleUpdate() : handleCreate();
          }}
          onCancel={() => {
            setShowModal(false);
            setIsEditing(false);
          }}
        >
          <ParentProductForm form={form} isEditing={isEditing} />
        </Modal>
      )}
      <div className="d-flex-center">
        <h1>Familias</h1>
        <Button
          type="primary"
          style={{
            marginLeft: "auto",
          }}
          onClick={() => {
            setShowModal(true);
            form.reset({
              asin: "",
              sku: "",
              title: "",
              children: [],
            });
            setIsEditing(false);
          }}
        >
          Crear nuevo producto padre
        </Button>
      </div>

      <div>
        <Collapse
          destroyInactivePanel={true}
          items={parentProducts.map((el) => {
            return {
              key: el.id,
              label: (
                <div className="d-flex">
                  <div style={{ minWidth: 120 }}>{el.sku}</div>
                  {el.children.length > 0 &&
                    el.children.map((child) => {
                      return (
                        <div>
                          <img
                            src={child?.spanish?.images?.MAIN}
                            alt=""
                            style={{
                              border: "1px solid #ccc",
                              marginRight: 2,
                              width: 50,
                              height: 50,
                            }}
                          />
                        </div>
                      );
                    })}
                  <div style={{ maxWidth: 600, marginLeft: 24 }}>
                    {el?.title}
                  </div>

                  <div
                    style={{ marginLeft: "auto" }}
                    className="d-flex-center"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <div style={{ marginRight: 12 }}>
                      <Select
                        onChange={(value) => {
                          setSelectedLanguage(value);
                        }}
                        value={selectedLanguage}
                        style={{ width: 120 }}
                        options={[
                          { value: "spanish", label: "España" },
                          { value: "german", label: "Alemania" },
                          { value: "french", label: "Francia" },
                          { value: "italian", label: "Italia" },
                          { value: "belgium", label: "Belgica" },
                          { value: "netherlands", label: "Paises bajos" },
                          { value: "poland", label: "Polonia" },
                          { value: "sweden", label: "Suecia" },
                        ]}
                      ></Select>
                    </div>

                    <div style={{ marginRight: 12 }}>
                      <Tooltip title="Este botón solo se puede usar si todos los hijos tienen un nombre de variante. Además, tienen que tener configurada la misma categoria">
                        <Button
                          type="primary"
                          onClick={() => handleCreateParentProductCsv(el)}
                          disabled={isGenerateBtnDisabled(el)}
                        >
                          Generar csv de familias
                        </Button>
                      </Tooltip>
                    </div>
                    <div>
                      <FaEdit
                        fontSize={24}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          form.reset({
                            originalAsin: el.asin,
                            asin: el.asin,
                            sku: el.sku,
                            title: el.title,
                            children: el.children.map((child) => child.asin),
                          });
                          setShowModal(true);
                          setIsEditing(true);
                        }}
                      />
                    </div>
                    <div style={{ marginRight: 12, marginLeft: 12 }}>
                      <Popconfirm
                        title="¿Estás seguro que quieres borrar este padre?"
                        onConfirm={(e) => {
                          ParentProductServices.deleteParentProduct(
                            el.asin
                          ).then(() => {
                            notification.success({
                              message: "El padre ha sido borrado",
                              description: "",
                            });
                            refresh();
                          });
                        }}
                      >
                        <MdDelete
                          fontSize={24}
                          style={{ cursor: "pointer", color: "crimson" }}
                          onClick={(e) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                          }}
                        />
                      </Popconfirm>
                    </div>
                  </div>
                </div>
              ),
              children: el.children.map((child) => {
                return (
                  <div className="d-flex-center" key={child.asin}>
                    <div>{child.asin}</div>
                    <div>
                      <img
                        height={100}
                        width={100}
                        src={child?.spanish?.images?.MAIN}
                        alt=""
                      />
                    </div>
                    <div style={{ maxWidth: 600 }}>
                      <Link to={`/products/${child.asin}`}>
                        {child?.spanish?.title}
                      </Link>
                    </div>
                    <div>
                      {child[selectedLanguage]?.variantName ? (
                        child[selectedLanguage].variantName
                      ) : (
                        <span style={{ color: "crimson" }}>
                          Sin nombre de variante
                        </span>
                      )}
                    </div>
                    <div style={{ marginLeft: 12 }}>
                      {child[selectedLanguage]?.productType ? (
                        child[selectedLanguage]?.productType
                      ) : (
                        <span style={{ color: "crimson" }}>Sin categoria</span>
                      )}
                    </div>
                  </div>
                );
              }),
            };
          })}
          defaultActiveKey={[]}
        />
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { WarehouseService } from "../services/WarehouseService.ts";
import { Select } from "antd";

interface WarehouseSelectProps {
  value: string;
  onChange: (value: string) => void;
  style?: any;
  showNAOption?: boolean;
}

export const WarehouseSelect = ({
  value,
  onChange,
  style,
  showNAOption = true,
}: WarehouseSelectProps) => {
  const [warehouses, setWarehouses] = useState<any>([]);
  useEffect(() => {
    WarehouseService.getAllWarehouses().then((data) => {
      setWarehouses(data.data);
    });
  }, []);

  let options: any = [];
  if (showNAOption) {
    options.push({
      value: "N/A",
      label: "N/A (no aplica)",
    });
  }
  options = [
    ...options,
    ...warehouses.map((el) => ({
      value: el._id,
      label: el.name,
    })),
  ];

  return (
    <Select style={style} value={value} onChange={onChange} options={options} />
  );
};

import { Button, Checkbox, Collapse, CollapseProps, Input, Switch } from "antd";
import React, { useEffect } from "react";
import { useLocation } from "wouter";
import { DeliveryToAwsService } from "../services/DeliveryToAwsService.ts";
import { DeliveryForm } from "../components/DeliveryForm.tsx";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { RightLayout } from "../components/RightLayout.tsx";

export const GenerateDeliveryToAwsPage = () => {
  const [location, setLocation] = useLocation();
  const [deliveries, setDeliveries] = React.useState([]);
  const [showProcessed, setShowProcessed] = React.useState(false);
  const [showNotProcessed, setShowNotProcessed] = React.useState(true);

  React.useEffect(() => {
    DeliveryToAwsService.getAllDeliveryToAws().then((res) => {
      setDeliveries(
        res.data.map((delivery) => {
          const title = `${delivery.isProcessed ? "Procesado" : "No procesado"}
              ${delivery.title}`;

          return {
            key: delivery._id,
            label: title,
            children: <DeliveryForm delivery={delivery} />,
            isProcessed: delivery.isProcessed,
          };
        })
      );
    });
  }, []);

  const whatToShow = () => {
    if (showProcessed && showNotProcessed) {
      return deliveries;
    }
    if (showProcessed) {
      return deliveries.filter((el) => el.isProcessed === true);
    }
    if (showNotProcessed) {
      return deliveries.filter((el) => el.isProcessed === false);
    }
    return [];
  };

  return (
    <RightLayout>
      <Button
        className="dg-item"
        type="primary"
        onClick={() => setLocation("/generate-delivery-to-aws/new")}
      >
        Generar envio a AWS
      </Button>

      <div>
        Mostrar no procesados{" "}
        <Checkbox
          checked={showNotProcessed}
          onChange={(e) => {
            setShowNotProcessed(e.target.checked);
          }}
        />
        Mostrar procesados{" "}
        <Checkbox
          checked={showProcessed}
          onChange={(e) => {
            setShowProcessed(e.target.checked);
          }}
        />
      </div>
      <div>
        <Collapse ghost items={whatToShow()} defaultActiveKey={["1"]} />
      </div>
    </RightLayout>
  );
};

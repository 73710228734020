export const getBase64 = (file): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const compareListingDataToBackendData = (
  listingData: any,
  backendData: any
) => {
  // Función auxiliar para comparar dos valores de manera profunda y registrar diferencias
  const deepCompare = (obj1: any, obj2: any, path: string = ""): any[] => {
    let differences: any[] = [];

    if (typeof obj1 !== typeof obj2) {
      differences.push({
        path,
        listingValue: obj1,
        backendValue: obj2,
      });
      return differences;
    }

    if (typeof obj1 === "object" && obj1 !== null && obj2 !== null) {
      if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) {
          differences.push({
            path,
            listingValue: obj1,
            backendValue: obj2,
          });
        } else {
          obj1.forEach((item, index) => {
            differences = differences.concat(
              deepCompare(item, obj2[index], `${path}[${index}]`)
            );
          });
        }
      } else {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        const allKeys = new Set([...keys1, ...keys2]);

        allKeys.forEach((key) => {
          differences = differences.concat(
            deepCompare(obj1[key], obj2[key], `${path ? `${path}.` : ""}${key}`)
          );
        });
      }
    } else if (obj1 !== obj2) {
      differences.push({
        path,
        listingValue: obj1,
        backendValue: obj2,
      });
    }

    return differences;
  };

  // Realizar la comparación y obtener las diferencias
  const differences = deepCompare(listingData, backendData);

  // Devolver el resultado de la comparación y el resumen de diferencias
  return {
    isEqual: differences.length === 0,
    differences: differences,
  };
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

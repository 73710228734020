import { Tabs, TabsProps } from "antd";
import React from "react";
import { ChatGptProptsForm } from "../components/ChatGptProptsForm.tsx";
import { BulletsAndKeywordsChecker } from "../components/BulletsAndKeywordsChecker.tsx";

const items: TabsProps["items"] = [
  {
    key: "chatGptPrompts",
    label: "Chatgpt Prompts",
    children: <ChatGptProptsForm />,
  },
  {
    key: "bulletsAndKeywordsChecker",
    label: "Comprobacion de Bullets y Keywords",
    children: <BulletsAndKeywordsChecker />,
  },
  //   {
  //     key: "3",
  //     label: "Tab 3",
  //     children: "Content of Tab Pane 3",
  //   },
];

export const SettingsPage = () => {
  return (
    <div>
      <h1>Configuración</h1>
      <Tabs defaultActiveKey="bulletsAndKeywordsChecker" items={items} />
    </div>
  );
};

import { notification, Spin, Tooltip } from "antd";
import React, { useContext, useState } from "react";
import { BsTranslate } from "react-icons/bs";
import { ChatService } from "../services/ChatService.ts";
import { DataContext } from "../context/DataContext.tsx";
import { LoadingOutlined } from "@ant-design/icons";

interface CopyFromAndTranslateProps {
  form: any;
  myKey: string;
  selectedLanguage: string;
  from?: string;
  height?: number;
  width?: number;
  color?: string;
}

export const CopyFromAndTranslate = ({
  form,
  myKey,
  selectedLanguage,
  from = "spanish",
  height = 24,
  width = 24,
  color = "blueviolet",
}: CopyFromAndTranslateProps) => {
  const { chatGptPrompts } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleTranslateClick = ({ force = false }) => {
    const languages = {
      german: "Alemán",
      french: "Francés",
      italian: "Italiano",
      belgium: "Francés",
      netherlands: "neerlandes",
      sweden: "Sueco",
      poland: "Polaco",
    };
    if (!myKey) {
      notification.error({
        message: "Falta el myKey",
        description: "Falta el myKey",
      });
      return;
    }

    const fullKey = `${selectedLanguage}.${myKey}`;
    const values = form.getValues();

    const originalValue = values[from][myKey];

    let result = "";
    if (chatGptPrompts["translate-hidden-component"]) {
      const lastItem =
        chatGptPrompts["translate-hidden-component"].prompt.length - 1;
      let prePrompt = "";
      if (chatGptPrompts["translate-hidden-component"].prompt?.length > 0) {
        prePrompt =
          chatGptPrompts["translate-hidden-component"].prompt[lastItem].text;
      }
      let finalPrompt = prePrompt.replace(
        "{{targetLanguage}}",
        languages[selectedLanguage]
      );
      result = finalPrompt.replace("{{selectedText}}", originalValue);
    }

    setIsLoading(true);
    ChatService.askToChat({
      message: result,
      force: force,
    })
      .then((res) => {
        form.setValue(fullKey, res.data.response);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  if (selectedLanguage === "spanish") {
    return null;
  }

  if (
    form.getValues()[from][myKey] === "" ||
    form.getValues()[from][myKey] === null ||
    form.getValues()[from][myKey] === undefined
  ) {
    return null;
  }

  if (isLoading)
    return (
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 16, color: "#666" }} spin />
        }
      />
    );

  return (
    <div className="d-flex-center">
      <div
        onDoubleClick={() => {
          handleTranslateClick({ force: true });
        }}
        onClick={handleTranslateClick}
        className="d-flex-center"
        style={{ marginLeft: 10 }}
      >
        <Tooltip
          title={`Este botón coge el texto de ${from}, lo traduce a ${selectedLanguage} y lo pone en el campo ${myKey}`}
        >
          <BsTranslate style={{ height: height, width: width, color: color }} />
        </Tooltip>
      </div>
      <div
        onClick={() => {
          handleTranslateClick({ force: true });
        }}
        className="d-flex-center"
        style={{ marginLeft: 10 }}
      >
        <Tooltip
          title={`Este botón coge el texto de ${from}, lo traduce a ${selectedLanguage} y lo pone en el campo ${myKey}. FUERZA TRADUCCION CHATGPT`}
        >
          <BsTranslate style={{ height: height, width: width, color: color }} />
        </Tooltip>
      </div>
    </div>
  );
};

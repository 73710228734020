import React, { useState } from "react";
import { Upload, Button, message, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RefundService } from "../services/RefundService.ts";

const CSVUploader = () => {
  const [file, setFile] = useState(null);

  const handleUpload = (info) => {
    setFile(info.file);
  };

  const handleSubmit = () => {
    if (file) {
      RefundService.uploadCSV(file).then((res) => {
        notification.success({
          message: "CSV Uploaded",
          description: "CSV Uploaded successfully",
        });
      });
    } else {
      message.warning("Please upload a file first");
    }
  };

  return (
    <div className="d-flex">
      <Upload
        accept=".csv"
        beforeUpload={() => false} // Prevenir la subida automática
        onChange={handleUpload}
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Select CSV File</Button>
      </Upload>
      <Button type="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default CSVUploader;

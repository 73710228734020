import { Button, Spin } from "antd";
import React from "react";
import { SpApiService } from "../services/SpApiService.ts";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { LoadingOutlined } from "@ant-design/icons";

export const BulletsAndKeywordsChecker = () => {
  const [data, setData] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleClick = async () => {
    setIsLoading(true);
    SpApiService.getTodayOrders()
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const generateCSV = () => {
    const csvData = Object.keys(data).map((key) => {
      const [sku, country] = key.split("_");
      const bulletPointsLength = data[key]?.bullet_point?.length || 0;
      const hasGenericKeywords = data[key]?.generic_keyword?.length > 0;
      if (data[key] === null) {
        return {
          sku,
          country,
          main_product_image: "null",
          bullet_points: "null",
          keywords: "null",
          need_action: "Si, el producto no existe en el marketplace",
        };
      } else {
        return {
          sku,
          country,
          main_product_image:
            data[key].main_product_image_locator[0].media_location ?? "null",
          bullet_points: bulletPointsLength,
          keywords: hasGenericKeywords ? "Si" : "No",
          need_action:
            bulletPointsLength < 5 || !hasGenericKeywords ? "Si" : "No",
        };
      }
    });

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const YYYY_MM_DD = new Date().toISOString().slice(0, 10); // YYYY_MM_DD
    const filename = `Comprobacion_bullets_y_keywords_${YYYY_MM_DD}.csv`;

    saveAs(blob, filename);
  };

  return (
    <div>
      <div className="dg-item">
        <Button disabled={isLoading} onClick={handleClick}>
          {isLoading ? (
            <div className="d-flex-center">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 16, color: "#666" }}
                    spin
                  />
                }
              />
              <span style={{ marginLeft: 8 }}>
                Esta operación puede tardar varios minutos
              </span>
            </div>
          ) : (
            "Realizar comprobación"
          )}
        </Button>
      </div>
      {Object.keys(data).length > 0 && (
        <div className="dg-item">
          <Button type="primary" onClick={generateCSV}>
            Descargar CSV
          </Button>
        </div>
      )}

      {Object.keys(data).map((key) => {
        const [sku, country] = key.split("_");
        return (
          <>
            {data[key] === null ? (
              <div className="warning dg-item">
                {sku} no encontrado en {country}
              </div>
            ) : (
              <div key={key} className="d-flex dg-item">
                <div className="mr-12">
                  <img
                    src={
                      data[key]?.main_product_image_locator?.[0]?.media_location
                    }
                    alt="product"
                    width="50"
                    height="50"
                  />
                </div>
                <div className="mr-12">{sku}</div>
                <div className="mr-12">{country}</div>
                <div className="mr-12">
                  Bullet points:{" "}
                  {data[key]?.bullet_point?.length ? (
                    <span>{data[key].bullet_point?.length}</span>
                  ) : (
                    <span className="warning">0</span>
                  )}
                </div>
                <div className="mr-12">
                  keywords:{" "}
                  {data[key]?.generic_keyword?.length > 0 ? (
                    <span>Si</span>
                  ) : (
                    <span className="warning">No</span>
                  )}
                </div>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

import React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Input, Button } from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  LinkOutlined,
} from "@ant-design/icons";

const TrackingNumbersForm = ({ fields, append, remove, control }) => {
  const handleTrackingNumberClick = (index) => () => {
    window.open(
      `https://parcelsapp.com/es/tracking/${fields[index].number}`,
      "_blank"
    );
  };

  return (
    <div className="">
      <div style={{ width: 800 }}>
        {fields.map((item, index) => (
          <div key={item.id} className="dg-item d-flex">
            <div style={{ width: 60, cursor: "pointer" }}>
              <LinkOutlined
                style={{ color: "#007bff" }}
                onClick={handleTrackingNumberClick(index)}
              />
            </div>
            <div style={{ width: 200 }}>Tracking number {index + 1}</div>
            <Controller
              name={`trackingNumbers[${index}].number`}
              control={control}
              render={({ field }) => (
                <Input {...field} style={{ width: 300 }} />
              )}
            />
            <Button
              type="dashed"
              onClick={() => remove(index)}
              icon={<MinusCircleOutlined />}
              disabled={fields.length === 1}
            >
              Remove
            </Button>
          </div>
        ))}
      </div>

      <div style={{ marginTop: 18, marginLeft: 10 }}>
        <Button
          type="dashed"
          onClick={() => append({ number: "" })}
          icon={<PlusOutlined />}
        >
          Añadir otro tracking number
        </Button>
      </div>
    </div>
  );
};

export default TrackingNumbersForm;

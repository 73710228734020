import { VscGitStashApply } from "react-icons/vsc";

import {
  Button,
  Input,
  Modal,
  notification,
  Popconfirm,
  Progress,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { Controller, set, UseFormReturn } from "react-hook-form";
import ImageUploader from "../ImageUploader.tsx";
import { UploadService } from "../../services/UploadService.ts";
import { AsinService } from "../../services/AsinService.ts";
import { ErrorMessage } from "../ErrorMessage.tsx";
import { CharCountBar } from "../CharCountBar.tsx";
import { DGCKeditor } from "../DGCKeditor.tsx";
import { XlsService } from "../../services/XlsService.ts";
import { IoInformationCircleOutline } from "react-icons/io5";
import { ChatService } from "../../services/ChatService.ts";
import { DataContext } from "../../context/DataContext.tsx";
import { SpApiService } from "../../services/SpApiService.ts";
import { MARKETPLACES_IDS } from "../../config.ts";
import { ProductTypesSearch } from "../ProductTypesSearch.tsx";
import { IoMdSync } from "react-icons/io";
import { refillForm } from "./ProductLanguageForm.utils.tsx";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { CopyFromAndTranslate } from "../CopyFromAndTranslate.tsx";
import { compareListingDataToBackendData } from "../../utils/utils.ts";
import { DiffViewer } from "../DiffViewer/DiffViewer.tsx";

interface ProductLanguageFormProps {
  form: UseFormReturn<any>;
  asinService: AsinService;
  selectedLanguage: string;
  asin: string;
  awsData: any;
}

dayjs.extend(relativeTime);

export const ProductLanguageForm = ({
  form,
  asinService,
  selectedLanguage,
  asin,
  awsData,
}: ProductLanguageFormProps) => {
  const [checkProductData, setCheckProductData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const { chatGptPrompts } = useContext(DataContext);
  const [lastUpdate, setLastUpdate] = useState("");
  const [isAwsLoading, setIsAwsLoading] = useState(false);
  useEffect(() => {
    if (selectedLanguage && awsData) {
      handleCheckExistence();
    }
  }, [selectedLanguage, awsData]);

  const handleImageChange = (imageUrl: any, type: string) => {
    const formValues = form.getValues();
    UploadService.upload(
      imageUrl,
      formValues.asin,
      selectedLanguage,
      type
    ).then((res) => {
      form.setValue(`${selectedLanguage}.images.${type}`, res);
      notification.success({
        message: "Image uploaded",
        description: "Image uploaded successfully",
      });
    });
  };

  const handleDeleteImage = (type: string) => {
    const values = form.getValues();

    UploadService.deleteUpload(values.asin, selectedLanguage, type).then(
      (res) => {
        form.setValue(`${selectedLanguage}.images.${type}`, "");

        notification.success({
          message: "Image deleted",
          description: "Image deleted successfully",
        });
      }
    );
  };

  const handleGenerateXls = () => {
    XlsService.generateXls({ asin, selectedLanguage }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = response.headers["x-filename"]; // Obtener el nombre del archivo de los headers

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // El nombre del archivo que se descargará
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      notification.success({
        message: "Success",
        description: "The file was generated successfully",
      });
    });
  };
  const categoryName = form.watch(`${selectedLanguage}.productType`, "");

  const isButtonDisabled = categoryName.trim() === "";

  const getPrompts = () => {
    const values = form.getValues();
    const keywords = values[selectedLanguage]?.keywords;
    // const languages = {
    //   de: "Alemán",
    //   fr: "Francés",
    //   it: "Italiano",
    //   en: "Inglés",
    //   bl: "Bélgica",
    //   pl: "Polaco",
    //   se: "Sueco",
    //   nl: "Holandés",
    // };

    const languages = {
      spanish: "Español",
      french: "Francés",
      german: "Alemán",
      italian: "Italiano",
      poland: "Polaco",
      sweden: "Sueco",
      belgium: "Bélgica",
      netherlands: "Holandés",
    };
    let result = {
      title: "",
      description: "",
      bullets: "",
    };

    if (chatGptPrompts["listing-generator-title"]) {
      result = {
        title: chatGptPrompts["listing-generator-title"].prompt[
          chatGptPrompts["listing-generator-title"].prompt.length - 1
        ].text
          .replace("{{keywords}}", keywords)
          .replace("{{selectedLanguage}}", languages[selectedLanguage]),
        description: chatGptPrompts["listing-generator-description"].prompt[
          chatGptPrompts["listing-generator-description"].prompt.length - 1
        ].text
          .replace("{{keywords}}", keywords)
          .replace("{{selectedLanguage}}", languages[selectedLanguage]),
        bullets: chatGptPrompts["listing-generator-bullets"].prompt[
          chatGptPrompts["listing-generator-bullets"].prompt.length - 1
        ].text
          .replace("{{keywords}}", keywords)
          .replace("{{selectedLanguage}}", languages[selectedLanguage]),
      };
    }
    return result;
  };

  const handleChatGptClick = async () => {
    const values = form.getValues();
    const keywords = values[selectedLanguage]?.keywords;
    setVisible(true);
    if (keywords) {
      const queries = getPrompts();

      await ChatService.askToChat({
        message: queries.title,
        force: false,
      }).then((res) => {
        setProgress((1 / Object.keys(queries).length) * 100);
        form.setValue(`${selectedLanguage}.title`, res.data.response);
      });

      await ChatService.askToChat({
        message: queries.description,
        force: false,
      }).then((res) => {
        setProgress((2 / Object.keys(queries).length) * 100);
        form.setValue(`${selectedLanguage}.description`, res.data.response);
      });

      await ChatService.askToChat({
        message: queries.bullets,
        force: false,
      }).then((res) => {
        setProgress((3 / Object.keys(queries).length) * 100);
        form.setValue(`${selectedLanguage}.bullets`, res.data.response);
      });
    } else {
      notification.error({
        message: "Falta palabras claves",
        description: "Falta palabras claves",
      });
    }
  };

  const handleCheckExistence = () => {
    const marketplaceId = MARKETPLACES_IDS.find(
      (el) => el.key === selectedLanguage
    )?.marketplaceId;

    if (!marketplaceId) {
      notification.error({
        message: "Falta el marketplace",
        description: "Falta el marketplace",
      });
      return;
    }

    if (awsData?.[selectedLanguage]) {
      if (awsData[selectedLanguage].issues.length > 0) {
        // notification.warning({
        //   message: `Se han encontrado ${awsData[selectedLanguage].issues.length} problemas en ${selectedLanguage}`,
        //   description: (
        //     <div>
        //       {awsData[selectedLanguage].issues.map(
        //         (el: any, index: number) => (
        //           <p key={index}>
        //             {el.attributeNames?.join(", ")} | {el.code}: {el.message}
        //           </p>
        //         )
        //       )}
        //     </div>
        //   ),
        //   style: {
        //     width: "70vw",
        //   },
        //   placement: "top",
        //   duration: 0,
        // });
        return;
      }
      if (awsData[selectedLanguage]?.summaries?.[0]?.lastUpdatedDate) {
        setLastUpdate(
          awsData[selectedLanguage]?.summaries?.[0]?.lastUpdatedDate
        );
      }
      if (
        awsData[selectedLanguage]?.summaries?.[0]?.asin !==
        form.getValues().asin
      ) {
        notification.warning({
          message: "El asin no coincide",
          description: "El asin no coincide con el registrado en amazon",
        });
        return;
      }
    } else {
      notification.error({
        message: "Error",
        description: `No existe en el marketplace: ${selectedLanguage}`,
      });
    }
  };

  const handleCreateFullProduct = () => {
    const marketplaceId = MARKETPLACES_IDS.find(
      (el) => el.key === selectedLanguage
    )?.marketplaceId;

    if (!marketplaceId) {
      notification.error({
        message: "Falta el marketplace",
        description: "Falta el marketplace",
      });
      return;
    }
    SpApiService.putAwsProductAttributesTest(
      form.getValues().sku,
      marketplaceId
    ).then((res) => {
      console.log(res.data);
    });
    setIsAwsLoading(true);
    SpApiService.putAwsProductAttributes(form.getValues().sku, marketplaceId)
      .then((res) => {
        setIsAwsLoading(false);
        if (
          res.data.data.status === "VALID" ||
          res.data.data.status === "ACCEPTED"
        ) {
          notification.info({
            message: "Producto creado",
            description: (
              <div>
                <p>
                  Ten en cuenta que el producto puede tardar hasta 24 horas en
                  aparecer en el marketplace
                </p>
                <p>
                  {res.data.data.sku}:{res.data.data.status}:
                  {res.data.data.submissionId}
                </p>
              </div>
            ),
            placement: "bottom",
          });
        }
        if (res.data.data.status === "INVALID") {
          notification.error({
            message: "Producto no creado",
            description: (
              <div>
                <p>La api ha devuelto invalid</p>
                {res.data.data.issues.map((el: any, index: number) => (
                  <p key={index}>
                    {el.code}: {el.message} {el?.attributeNames?.join(", ")}
                  </p>
                ))}
              </div>
            ),
            placement: "bottom",
            duration: 0,
          });
        }
      })
      .catch((err) => {
        setIsAwsLoading(false);
      });
  };

  const handleCreateFullProductTest = () => {
    setIsAwsLoading(true);
    const marketplaceId = MARKETPLACES_IDS.find(
      (el) => el.key === selectedLanguage
    )?.marketplaceId;

    if (!marketplaceId) {
      notification.error({
        message: "Falta el marketplace",
        description: "Falta el marketplace",
      });
      return;
    }

    SpApiService.putAwsProductAttributesTest(
      form.getValues().sku,
      marketplaceId
    )
      .then((res) => {
        setIsAwsLoading(false);
        console.log(res.data);

        if (
          res.data.data.status === "VALID" ||
          res.data.data.status === "ACCEPTED"
        ) {
          notification.info({
            message: "Producto testeado",
            description: (
              <div>
                <p>
                  Ten en cuenta que el producto puede tardar hasta 24 horas en
                  aparecer en el marketplace
                </p>
                <p>
                  {res.data.data.sku}:{res.data.data.status}:
                  {res.data.data.submissionId}
                </p>
              </div>
            ),
            placement: "bottom",
          });
        }
        if (res.data.data.status === "INVALID") {
          notification.error({
            message: "Producto no creado",
            description: (
              <div>
                <p>La api ha devuelto invalid</p>
                {res.data.data.issues.map((el: any, index: number) => (
                  <p key={index}>
                    {el.code}: {el.message} {el?.attributeNames?.join(", ")}
                  </p>
                ))}
              </div>
            ),
            placement: "bottom",
            duration: 0,
          });
        }
      })
      .catch((err) => {
        setIsAwsLoading(false);
      });
  };

  const handleDeleteListing = () => {
    setIsAwsLoading(true);

    const sku = form.getValues().sku;

    const marketplaceId = MARKETPLACES_IDS.find(
      (el) => el.key === selectedLanguage
    )?.marketplaceId;

    if (!marketplaceId) {
      notification.error({
        message: "Falta el marketplace",
        description: "Falta el marketplace",
      });
      return;
    }

    SpApiService.deleteListing({ sku, marketplaceId })
      .then(() => {
        setIsAwsLoading(false);
        notification.success({
          message: "Listing deleted",
          description: "Listing deleted successfully",
        });
      })
      .catch((err) => {
        setIsAwsLoading(false);
      });
  };

  const handleDownloadAmazonData = () => {
    setIsAwsLoading(true);
    const marketplaceId = MARKETPLACES_IDS.find(
      (el) => el.key === selectedLanguage
    )?.marketplaceId;
    if (!marketplaceId) {
      return;
    }

    SpApiService.getAwsProductAttributes(form.getValues().sku, marketplaceId)
      .then(async (res) => {
        await refillForm({
          form,
          data: res.data,
          selectedLanguage,
          chatGptPrompts,
        });
        notification.success({
          message: "Sincronizacion finalizada",
        });
        setIsAwsLoading(false);
      })
      .catch((err) => {
        setIsAwsLoading(false);
      });
  };

  const handleCheckListing = async () => {
    const marketplaceId = MARKETPLACES_IDS.find(
      (el) => el.key === selectedLanguage
    )?.marketplaceId;

    if (!marketplaceId) {
      notification.error({
        message: "Falta el marketplace",
        description: "Falta el marketplace",
      });
      return;
    }

    try {
      setIsAwsLoading(true);

      const listingData = await SpApiService.getListingInfo({
        asin: form.getValues().asin,
        marketplaceId,
      });
      const backendData = await SpApiService.getAwsProductAttributes(
        form.getValues().sku,
        marketplaceId
      );

      const result = compareListingDataToBackendData(
        listingData.data.attributes,
        backendData.data.attributes
      );
      console.log("back front comparation", { result });
      setCheckProductData(result);
      setIsAwsLoading(false);
    } catch (error) {
      console.log(error);

      setIsAwsLoading(false);
    }
  };

  return (
    <div>
      {checkProductData && (
        <Modal
          width={"80vw"}
          title={`Resultado de la comprobación en ${selectedLanguage}`}
          open={true}
          footer={null}
          onCancel={() => setCheckProductData(null)}
        >
          <DiffViewer differences={checkProductData.differences} />
        </Modal>
      )}

      <Modal
        title="Progreso de las Llamadas"
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Progress percent={progress} showInfo={false} />
      </Modal>
      <div className="d-flex-center dg-item">
        Ultima actualizacion amazon:{" "}
        {dayjs(lastUpdate).format("DD/MM/YYYY HH:mm")} - Hace{" "}
        {dayjs(lastUpdate).fromNow(true)} (Creo que hay que restarle 2 horas)
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
        className=" dg-item"
      >
        <div>
          <div className="d-flex-center" style={{ flex: 1 }}>
            <div className="dg-item">
              <Button type="primary" onClick={handleCheckExistence}>
                Comprobar errores en {selectedLanguage}
              </Button>
            </div>
            <div className="dg-item">
              <Tooltip
                title={`Este botón sirve para comprobar si existe en el marketplace seleccionado. (${selectedLanguage})`}
              >
                <IoInformationCircleOutline
                  color="#000022"
                  fontSize={24}
                  style={{ marginLeft: 10 }}
                />
              </Tooltip>
            </div>
          </div>
          <div className="d-flex-center dg-item">
            <Button
              type="primary"
              onClick={handleGenerateXls}
              disabled={isButtonDisabled}
            >
              Generar archivo para subir
            </Button>
            <Tooltip title="Este botón sirve para generar un archivo para subir a Amazon. Cada pestaña genera su correspondiente archivo y HAY QUE SUBIRLO EN EL MARKETPLACE CORRESPONDIENTE. Por ejemplo, si generas el de alemania, tienes que ir al marketplace alemania y darle a subir fichero (desde alemania). Si lo haces en el marketplace que no corresponde, vas a machacar los datos de ese marketplace.">
              <IoInformationCircleOutline
                color="#000022"
                fontSize={24}
                style={{ marginLeft: 10 }}
              />
            </Tooltip>
          </div>
        </div>
        <div>
          <div>
            <div className="dg-item d-flex-end">
              <Button
                className="aws-btn"
                type="primary"
                onClick={handleCheckListing}
                disabled={isAwsLoading}
              >
                Comprobar listing
              </Button>
            </div>

            <div className="dg-item d-flex-end">
              <Button
                className="aws-btn"
                type="primary"
                onClick={handleDownloadAmazonData}
                disabled={isAwsLoading}
              >
                Descargar datos de amazon
              </Button>
            </div>
            <div className="dg-item d-flex-end">
              <Button
                type="primary"
                // className="aws-btn"
                onClick={handleCreateFullProductTest}
                disabled={isAwsLoading}
              >
                Comprobar datos de amazon
              </Button>
            </div>
            <div className="dg-item d-flex-end">
              <Popconfirm
                title="Este botón creará o actualizará el producto en el marketplace seleccionado. Si el producto ya existe, se actualizará. Si no existe, se creará."
                onConfirm={handleCreateFullProduct}
              >
                <Button
                  type="primary"
                  className="aws-btn"
                  disabled={isAwsLoading}
                >
                  Crear o actualizar en amazon{" "}
                  {
                    MARKETPLACES_IDS.find((el) => el.key === selectedLanguage)
                      ?.countryLabel
                  }
                </Button>
              </Popconfirm>
            </div>
            <div>
              <Popconfirm
                title="Este botón borrará el producto."
                onConfirm={handleDeleteListing}
                disabled={isAwsLoading}
              >
                <Button
                  disabled={isAwsLoading}
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "crimson",
                    color: "white",
                    borderColor: "crimson",
                  }}
                >
                  Borrar listing de amazon, cuidado!!!
                  {
                    MARKETPLACES_IDS.find((el) => el.key === selectedLanguage)
                      ?.countryLabel
                  }
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex-center dg-item">
        <div style={{ width: 200 }}>
          productType{" "}
          <span
            onClick={() => {
              const currentValue =
                form.getValues()[selectedLanguage]?.productType;
              MARKETPLACES_IDS.forEach((el) => {
                form.setValue(`${el.key}.productType`, currentValue);
              });
            }}
          >
            <Tooltip title="Este botón va a cambiar el productType de todos los idiomas a que estas viendo ahora mismo.">
              <VscGitStashApply style={{ color: "#007bff" }} />
            </Tooltip>
          </span>
        </div>
        <Controller
          name={`${selectedLanguage}.productType`}
          control={form.control}
          render={({ field }) => {
            return (
              <div style={{ width: "100%" }}>
                <Input {...field} />
              </div>
            );
          }}
        />
      </div>

      <div className="d-flex dg-item">
        <div style={{ width: 200 }}>Titulo</div>
        <Controller
          name={`${selectedLanguage}.title`}
          control={form.control}
          render={({ field }) => (
            <div style={{ width: "100%" }}>
              <CharCountBar
                successLength={200}
                valueLength={field.value?.length || 0}
              />
              <Input {...field} />
              <CopyFromAndTranslate
                from="spanish"
                selectedLanguage={selectedLanguage}
                myKey="title"
                form={form}
              />
              <ErrorMessage form={form} name="title" />
            </div>
          )}
        />
      </div>

      <div className="d-flex dg-item">
        <div style={{ width: 200 }}>Descripcion</div>
        <Controller
          name={`${selectedLanguage}.description`}
          control={form.control}
          render={({ field }) => {
            return (
              <div style={{ width: "100%" }}>
                <CharCountBar
                  successLength={2000}
                  valueLength={field.value?.length || 0}
                />
                <DGCKeditor showCopyButton {...field} />
                <CopyFromAndTranslate
                  from="spanish"
                  selectedLanguage={selectedLanguage}
                  myKey="description"
                  form={form}
                />
              </div>
            );
          }}
        />
      </div>

      <div className="d-flex dg-item">
        <div style={{ width: 200 }}>Bullets</div>
        <Controller
          name={`${selectedLanguage}.bullets`}
          control={form.control}
          render={({ field }) => (
            <div className="d-flex-cednter" style={{ width: "100%" }}>
              <TextArea {...field} rows={5} />
              <CopyFromAndTranslate
                from="spanish"
                selectedLanguage={selectedLanguage}
                myKey="bullets"
                form={form}
              />
            </div>
          )}
        />
      </div>

      <div className="d-flex dg-item">
        <div style={{ width: 200 }}>
          Palabras claves{" "}
          {/* <Popconfirm
            title={
              <div style={{ width: 800 }}>
                <p style={{ marginTop: 0 }}>
                  Esta acción va a coger las palabras clave, y le va a pedir a
                  chatgpt que genere un titulo, una descripcion y los bullets
                  points.
                </p>

                <p>
                  <span className="warning">
                    VA A SOBREESCRIBIR LO QUE HAY ACTUALMENTE EN ESOS CAMPOS
                  </span>
                  . No se salvara hasta que le des a guardar, asi que si no te
                  gusta el resultado y quieres el anterior, puedes actualizar la
                  pagina.
                </p>
                <p>
                  ¿Seguro que quieres continuar?{" "}
                  <b>El prompt que vas a lanzar es este:</b>
                </p>
                <p style={{ color: "blueviolet" }}>{getPrompts()?.title}</p>
                <p style={{ color: "blueviolet" }}>
                  {getPrompts()?.description}
                </p>
                <p style={{ color: "blueviolet" }}>{getPrompts()?.bullets}</p>
              </div>
            }
            onConfirm={handleChatGptClick}
          > */}
          <img
            onClick={handleChatGptClick}
            src="https://openai.com/favicon.ico"
            alt=""
            height={24}
            width={24}
            style={{
              cursor: "pointer",
              borderRadius: 50,
            }}
          />
          {/* </Popconfirm> */}
        </div>
        <Controller
          name={`${selectedLanguage}.keywords`}
          control={form.control}
          render={({ field }) => {
            return (
              <div style={{ width: "100%" }}>
                <CharCountBar
                  successLength={500}
                  valueLength={field.value?.length || 0}
                />
                <Input {...field} />
                <CopyFromAndTranslate
                  from="spanish"
                  selectedLanguage={selectedLanguage}
                  myKey="keywords"
                  form={form}
                />
              </div>
            );
          }}
        />
      </div>
      <div className="d-flex dg-item">
        <div style={{ width: 200 }}>Youtube link</div>
        <Controller
          name={`${selectedLanguage}.youtubeLink`}
          control={form.control}
          render={({ field }) => {
            return (
              <div style={{ width: "100%" }}>
                <Input {...field} />
              </div>
            );
          }}
        />
      </div>

      <div className="d-flex dg-item">
        <ImageUploader
          type="MAIN"
          originalImageUrl={
            form.getValues()?.[selectedLanguage]?.images?.MAIN ?? ""
          }
          onImageChange={(imageUrl) => handleImageChange(imageUrl, "MAIN")}
          onImageDelete={() => handleDeleteImage("MAIN")}
        />
        <ImageUploader
          type="PT01"
          originalImageUrl={
            form.getValues()?.[selectedLanguage]?.images?.PT01 ?? ""
          }
          onImageChange={(imageUrl) => handleImageChange(imageUrl, "PT01")}
          onImageDelete={() => handleDeleteImage("PT01")}
        />
        <ImageUploader
          type="PT02"
          originalImageUrl={
            form.getValues()?.[selectedLanguage]?.images?.PT02 ?? ""
          }
          onImageChange={(imageUrl) => handleImageChange(imageUrl, "PT02")}
          onImageDelete={() => handleDeleteImage("PT02")}
        />
        <ImageUploader
          type="PT03"
          originalImageUrl={
            form.getValues()?.[selectedLanguage]?.images?.PT03 ?? ""
          }
          onImageChange={(imageUrl) => handleImageChange(imageUrl, "PT03")}
          onImageDelete={() => handleDeleteImage("PT03")}
        />
        <ImageUploader
          type="PT04"
          originalImageUrl={
            form.getValues()?.[selectedLanguage]?.images?.PT04 ?? ""
          }
          onImageChange={(imageUrl) => handleImageChange(imageUrl, "PT04")}
          onImageDelete={() => handleDeleteImage("PT04")}
        />
        <ImageUploader
          type="PT05"
          originalImageUrl={
            form.getValues()?.[selectedLanguage]?.images?.PT05 ?? ""
          }
          onImageChange={(imageUrl) => handleImageChange(imageUrl, "PT05")}
          onImageDelete={() => handleDeleteImage("PT05")}
        />
        <ImageUploader
          type="PT06"
          originalImageUrl={
            form.getValues()?.[selectedLanguage]?.images?.PT06 ?? ""
          }
          onImageChange={(imageUrl) => handleImageChange(imageUrl, "PT06")}
          onImageDelete={() => handleDeleteImage("PT06")}
        />
      </div>
      <div className="d-flex dg-item">
        <h3>Campos para los csvs</h3>
      </div>

      <div className="d-flex-center dg-item">
        <div style={{ minWidth: 280 }}>Nombre de variante</div>
        <Controller
          name={`${selectedLanguage}.variantName`}
          control={form.control}
          render={({ field }) => {
            return (
              <div className="d-flex-center" style={{ width: "600px" }}>
                <Input {...field} />
                <CopyFromAndTranslate
                  from="spanish"
                  selectedLanguage={selectedLanguage}
                  myKey="variantName"
                  form={form}
                />
              </div>
            );
          }}
        />
      </div>
      <div className="d-flex-center dg-item">
        <div style={{ minWidth: 280 }}>recommended_browse_nodes</div>
        <Controller
          name={`${selectedLanguage}.recommended_browse_nodes`}
          control={form.control}
          render={({ field }) => {
            return (
              <div className="d-flex-center" style={{ width: "375px" }}>
                <Input {...field} />{" "}
                <a
                  href={`https://www.amazon.${
                    MARKETPLACES_IDS.find((el) => el.key === selectedLanguage)
                      ?.urlRootDomain
                  }/b/ref=dp_bc_aui_C_5?ie=UTF8&node=${field.value}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: "10px" }}
                >
                  Abrir categoria
                </a>
                <a
                  style={{ marginLeft: "10px" }}
                  href={`https://www.amazon.${
                    MARKETPLACES_IDS.find((el) => el.key === selectedLanguage)
                      ?.urlRootDomain
                  }/dp/${form.getValues().asin}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Abrir PRODUCTO
                </a>
              </div>
            );
          }}
        />
      </div>
      <div className="d-flex-center dg-item">
        <div style={{ minWidth: 280 }}>list_price</div>
        <Controller
          name={`${selectedLanguage}.list_price`}
          control={form.control}
          render={({ field }) => {
            return (
              <div style={{ width: "600px" }}>
                <Input {...field} />
              </div>
            );
          }}
        />
        <span style={{ color: "red" }}>
          {MARKETPLACES_IDS.find((el) => el.key === selectedLanguage)?.currency}
        </span>
      </div>
      <div className="d-flex-center dg-item">
        <div style={{ minWidth: 280 }}>material</div>
        <Controller
          name={`${selectedLanguage}.material`}
          control={form.control}
          render={({ field }) => {
            return (
              <div className="d-flex-center" style={{ width: "600px" }}>
                <Input {...field} />
                <CopyFromAndTranslate
                  from="spanish"
                  selectedLanguage={selectedLanguage}
                  myKey="material"
                  form={form}
                />
              </div>
            );
          }}
        />
      </div>

      <div className="d-flex-center dg-item">
        <div style={{ minWidth: 280 }}>item_shape</div>
        <Controller
          name={`${selectedLanguage}.item_shape`}
          control={form.control}
          render={({ field }) => {
            return (
              <div className="d-flex-center" style={{ width: "600px" }}>
                <Input {...field} />
                <CopyFromAndTranslate
                  from="spanish"
                  selectedLanguage={selectedLanguage}
                  myKey="item_shape"
                  form={form}
                />
              </div>
            );
          }}
        />
      </div>

      <div className="d-flex-center dg-item">
        <div style={{ minWidth: 280 }}>special_feature</div>
        <Controller
          name={`${selectedLanguage}.special_feature`}
          control={form.control}
          render={({ field }) => {
            return (
              <div className="d-flex-center" style={{ width: "600px" }}>
                <Input {...field} />
                <CopyFromAndTranslate
                  from="spanish"
                  selectedLanguage={selectedLanguage}
                  myKey="special_feature"
                  form={form}
                />
              </div>
            );
          }}
        />
      </div>

      <div className="d-flex-center dg-item">
        <div style={{ minWidth: 280 }}>included_components</div>
        <Controller
          name={`${selectedLanguage}.included_components`}
          control={form.control}
          render={({ field }) => {
            return (
              <div className="d-flex-center" style={{ width: "600px" }}>
                <Input {...field} />
                <CopyFromAndTranslate
                  from="spanish"
                  selectedLanguage={selectedLanguage}
                  myKey="included_components"
                  form={form}
                />
              </div>
            );
          }}
        />
      </div>

      <div className="d-flex-center dg-item">
        <div style={{ minWidth: 280 }}>specific_uses_for_product</div>
        <Controller
          name={`${selectedLanguage}.specific_uses_for_product`}
          control={form.control}
          render={({ field }) => {
            return (
              <div className="d-flex-center" style={{ width: "600px" }}>
                <Input {...field} />
                <CopyFromAndTranslate
                  from="spanish"
                  selectedLanguage={selectedLanguage}
                  myKey="specific_uses_for_product"
                  form={form}
                />
              </div>
            );
          }}
        />
      </div>
      <div className="d-flex-center dg-item">
        <div style={{ minWidth: 280 }}>directions (instrucciones de uso)</div>
        <Controller
          name={`${selectedLanguage}.directions`}
          control={form.control}
          render={({ field }) => {
            return (
              <div className="d-flex-center" style={{ width: "600px" }}>
                <Input {...field} />
                <CopyFromAndTranslate
                  from="spanish"
                  selectedLanguage={selectedLanguage}
                  myKey="directions"
                  form={form}
                />
              </div>
            );
          }}
        />
      </div>

      <div className="d-flex-center dg-item">
        <div style={{ minWidth: 280 }}>warranty_description</div>
        <Controller
          name={`${selectedLanguage}.warranty_description`}
          control={form.control}
          render={({ field }) => {
            return (
              <div className="d-flex-center" style={{ width: "600px" }}>
                <Input {...field} />
                <CopyFromAndTranslate
                  from="spanish"
                  selectedLanguage={selectedLanguage}
                  myKey="warranty_description"
                  form={form}
                />
              </div>
            );
          }}
        />
      </div>

      <div className="d-flex-center dg-item">
        <div style={{ minWidth: 280 }}>recommended_uses_for_product</div>
        <Controller
          name={`${selectedLanguage}.recommended_uses_for_product`}
          control={form.control}
          render={({ field }) => {
            return (
              <div className="d-flex-center" style={{ width: "600px" }}>
                <Input {...field} />
                <CopyFromAndTranslate
                  from="spanish"
                  selectedLanguage={selectedLanguage}
                  myKey="recommended_uses_for_product"
                  form={form}
                />
              </div>
            );
          }}
        />
      </div>

      <div className="dg-item">
        <ProductTypesSearch selectedLanguage={selectedLanguage} />
      </div>
    </div>
  );
};

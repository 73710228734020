import axios from "axios";

export class DeliveryToAwsService {
  static async createDeliveryToAws(data: any) {
    return axios.post("/delivery-to-aws", data);
  }
  static async getAllDeliveryToAws() {
    return axios.get("/delivery-to-aws");
  }
  static async updateDeliveryToAws(id: string, data: any) {
    return axios.put(`/delivery-to-aws/${id}`, data);
  }
  static async getDeliveryToAwsById(id: string) {
    return axios.get(`/delivery-to-aws/${id}`);
  }
}
